import { db } from "src/component/alumnibuild/components/firebase";
import { collection, query, where, getDocs, updateDoc, doc, addDoc, getDoc } from "firebase/firestore";
import { saveFile, getFile } from "./files-data-helpers";
import { auth } from "../components/firebase";
import { hasUrlProtocol, isBase64ImageValid } from "../utils/string-utils";

export async function getUserDataById(id) {
  return getUserById(id);
}

export async function getUserDataByEmail(email) {
  return getUserDataByField('email', email);
}

export async function getCurrentAuthUser() {
  return getUserDataByField('email', auth.currentUser.email);
}

export function isUserLoggedIn() {
  return auth.currentUser ? true : false;
}

export function getUserInterestList(data) {
  let result = [];

  if (data["regions_of_interest"]) {
    result.push(...data["regions_of_interest"].slice(0, 3));
  }

  if (data["industries_of_interest"]) {
    result.push(...data["industries_of_interest"].slice(0, 3));
  }

  if (data["research_themes_of_interest"]) {
    result.push(...data["research_themes_of_interest"].slice(0, 3));
  }

  if (data["hobbies_of_interest"]) {
    result.push(...data["hobbies_of_interest"].slice(0, 3));
  }

  return result;
}

export async function getUserById(userId) {
  const reference = doc(db, "users", userId);
  const snapshot = await getDoc(reference);

  if (snapshot.exists) {
    const data = snapshot.data();
    const image = await getUserImage(userId);
    
    if (data['linkedin']) {
      data['linkedin'] = hasUrlProtocol(data['linkedin']) ? data['linkedin'] : 'http://' + data['linkedin'];
    }

    if (data['website']) {
      data['website'] = hasUrlProtocol(data['website']) ? data['website'] : 'http://' + data['website'];
    }

    return {
      'id': userId,
      ...data,
      'linkedin': data['linkedin'] ? data['linkedin'] : undefined,
      'website': data['website'] ? data['website'] : undefined,
      'interest': getUserInterestList(data),
      'image': image
    };
  } else {
    return undefined
  }
}

export async function getUserDataByField(fieldName, fieldValue) {
  const reference = collection(db, "users");
  const snapshot = await getDocs(
    query(
      reference,
      where(fieldName, "==", fieldValue)
    )
  );

  if (snapshot.size !== 0) {
    const userId = snapshot.docs[0].id;
    const image = await getUserImage(userId);

    return {
      'id': userId,
      ...snapshot.docs[0].data(),
      'image': image
    };
  } else {
    return undefined;
  }
}

export async function updateUserData(userId, fields) {
  const currentImage = await getUserImage(userId);
  
  if (fields.image &&  fields.image !== currentImage) {
    const isImageValid = await isBase64ImageValid(fields.image);

    if(!isImageValid){
      throw new Error("Invalid profile photo.");
    }

    await saveFile( `users/${userId}/profile-image.jpg`, fields.image);
  }

  if (fields.image) {
    delete fields.image;
  }

  const reference = doc(db, "users", userId);

  return updateDoc(reference, { ...fields, 'updated': new Date().toISOString() } );
}

export async function createUser(user) {
  const reference = collection(db, 'users');
  
  return addDoc(reference, {
    ...user,
    'created': new Date().toISOString()
  });
}

export async function getUserImage(userId) {
  //return getFile(`users/${userId}/profile-image.jpg`);
  
  return `https://firebasestorage.googleapis.com/v0/b/ab-firebase-34709.appspot.com/o/users%2F${userId}%2Fprofile-image.jpg?alt=media`;
}
