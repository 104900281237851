import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, Media, CardBody, ListGroup, ListGroupItem } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container } from "reactstrap";
import {  useParams, useNavigate, Link } from "react-router-dom";
import { getUserNotifications, removeNotification } from "../data-helpers/notifications-data-helpers";

const Notifications = (props) => {
  const currentUserLoggedId = localStorage.getItem("userId");
  const [ notificationList, setNotificationList ] = useState([]);
  const [ isReady, setIsReady ] = useState(false);
  const history = useNavigate();

  const getData = async () => {
    setIsReady(false);
    await getNotifications();
    setIsReady(true);
  }

  const getNotifications = async () => {
    const data = await getUserNotifications(currentUserLoggedId);
    
    setNotificationList(data);
  }

  const onNotificationClicked = async (notification) => {
    await removeNotification(notification.id);
    
    history(`${process.env.PUBLIC_URL}/${notification.route}`);
  }

  useEffect(() => {
    const interval = setInterval(() => { getNotifications(); }, 5000);

    getData();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="" title="Notifications"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            { isReady && <CardBody>
              <div className="cont b-light user-profile p-0">
              <ListGroup>
                {notificationList.length === 0 ? <div className="text text-muted p-2">No notifications found.</div> : ''}
                {notificationList.map((notification, i) => {
                return (
                  <ListGroupItem
                    className={notification.is_unread ? "list-group-item-action bg-light txt-dark" : "list-group-item-action"}
                    tag="button"
                    onClick={() =>onNotificationClicked(notification)}
                    key={i}
                  >
                    <Row>
                      <Col md="1">
                        <Media
                          body
                          className="rounded-circle"
                          src={notification.from_user_image}
                          alt=""
                        /> 
                      </Col>
                      <Col>
                        <Row>
                          <Col md="10">
                            <h6> { notification.content } </h6>
                          </Col>
                          <Col className="text-end">
                            <small>{ notification.readable_date }</small>
                          </Col>
                        </Row>
                        <Row>
                          <div className="d-flex justify-content-start">
                            <Link
                              to={`${process.env.PUBLIC_URL}/user/profile/${notification.from_user_id}`}
                            >
                              View profile
                            </Link>
                            { notification.isMessageEnabled &&
                              <Link
                                className="mx-4"
                                to={`${process.env.PUBLIC_URL}/my-connections/`}
                              >
                                Message
                              </Link>
                            }
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  );
                })}
               </ListGroup>
              </div>
            </CardBody> }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Notifications;
