import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, Media, CardBody } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container } from "reactstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getUserDataById } from "../data-helpers/user-data-helpers";
import { getIsAlreadySentInvitationToUser, createDonorConnection, getHasInvitationPendingFromUser, getIsUserInMyNetwork, acceptInvitationPendingFromUser } from "../data-helpers/donor-connections-data-helpers";

const AlumniProfile = (props) => {
  const [data, setData] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [ hasInvitationPendingFromUser, setHasInvitationPendingFromUser ] = useState(false);
  const [ isUserInMyNetwork, setIsUserInMyNetwork ] = useState(false);
  const [ isAlreadySentInvitationToUser, setIsAlreadySentInvitationToUser ] = useState(false);
  const { id } = useParams();
  const history = useNavigate();
  const currentUserLoggedId = localStorage.getItem("userId");
  const isProfileOfCurrentUser = id === currentUserLoggedId;

  const getData = async () => {
    setIsReady(false);

    const userFullData = await getUserDataById(id);
    
    if (userFullData) {
      setData(userFullData);
      await getConnectionData();
    } else {
      console.log("User not found.");
      history(`${process.env.PUBLIC_URL}/login`);
    }

    setIsReady(true);
  }

  const getConnectionData = async () => {
    const hasInvitationPendingFromUser = await getHasInvitationPendingFromUser(id, currentUserLoggedId);
    const isUserInMyNetwork = await getIsUserInMyNetwork(id, currentUserLoggedId);
    const isAlreadySentInvitationToUser = await getIsAlreadySentInvitationToUser(id, currentUserLoggedId);

    setHasInvitationPendingFromUser(hasInvitationPendingFromUser);
    setIsUserInMyNetwork(isUserInMyNetwork);
    setIsAlreadySentInvitationToUser(isAlreadySentInvitationToUser);
  }

  const onAcceptInvitationClicked = async () => {
    setIsProcessing(true);
    
    await acceptInvitationPendingFromUser({ 'from_user_id': id, 'to_user_id': currentUserLoggedId });
    
    setIsProcessing(false);
    
    history(0);
  }

  const onRequestConnectionClicked = async () => {
    setIsProcessing(true);
    
    await createDonorConnection({
      'fromUserId': currentUserLoggedId,
      'toUserId': id
    });
   
    setIsProcessing(false);
   
    history(0);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Donor" title="" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <CardBody>
              { isReady && <div className="cont b-light user-profile p-0">
                <Card className="card hovercard text-center mb-0">
                  <CardHeader className="cardheader background-image-container p-0 w-100">
                  </CardHeader>
                  <div className="user-image">
                    <div className="avatar">
                      <Media
                        body
                        src={data.image}
                        data-intro="This is Profile image"
                      />
                    </div>
                  </div>
                  <div className="info w-100">
                    <Row>
                      <Col lg="12" className="text-start">
                        <div className="title font-weight-bold">
                          { data.first_name ? `${data.first_name} ${data.last_name}` : '' }
                        </div>
                        { data.country && <div className="py-1">
                          <i className="fa fa-map-marker"></i> <span>{ data.country }</span>
                        </div> }
                        { data.country_of_origin && <div>
                          <span className="txt-primary">Country of origin:</span> { data.country_of_origin }
                        </div> }
                        { data.industry && <div>
                          <span className="txt-primary">Industry:</span> { data.industry }
                        </div> }
                        { data.looking_to && data.looking_to.length > 0 && <div>
                          <span className="txt-primary">Looking to:</span> { data.looking_to.join(", ") }
                        </div> }
                        { data.interest && data.interest.length > 0 && <div>
                          <span className="txt-primary">Interests:</span> { data.interest.join(", ") }
                        </div> }
                        { data.website &&<div className="py-1">
                          <i className="fa fa-link"></i> <a className="p-0" href={data.website} target="_blank">{ data.website }</a>
                        </div> }
                        <div
                          className="social-media"
                          data-intro="This is your Social details"
                        >
                          <ul className="list-inline">
                            { data.linkedin && <li className="list-inline-item">
                              <a className="p-0" href={ data.linkedin || 'https://linkedin.com/' } target="_blank">
                                <i className="fa fa-linkedin"></i>
                              </a>
                            </li>}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>

                { !isProfileOfCurrentUser &&
                  <div className="media">
                    <div className="media-body p-4 text-center">
                      <h4 className="f-w-600">
                          { !isUserInMyNetwork && !isAlreadySentInvitationToUser && !hasInvitationPendingFromUser &&
                            <button className="btn btn-primary" disabled={isProcessing} onClick={onRequestConnectionClicked}>
                              <span>
                                <i className="fa fa-plus text-white"></i>
                              </span> Connect
                            </button>
                          }
                          { isAlreadySentInvitationToUser &&
                            <button className="btn btn-light" disabled={isProcessing}>
                              <span>
                                <i className="fa fa-plus text-white"></i>
                              </span> Pending
                            </button>
                          }
                          { hasInvitationPendingFromUser &&
                          <button className="btn btn-primary" disabled={isProcessing} onClick={onAcceptInvitationClicked}>
                            <span>
                              <i className="fa fa-check text-white"></i>
                            </span> Accept invitation
                          </button>
                          }
                          { isUserInMyNetwork &&
                            <Link 
                              className="btn btn-primary"
                              to={`${process.env.PUBLIC_URL}/my-connections`}
                            >
                              <span>
                                <i className="pe-7s-chat text-white"></i>
                              </span> Message
                            </Link>
                          }
                      </h4>
                    </div>
                  </div>
                } 
              </div> }
            </CardBody>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AlumniProfile;
