import React, { useState, useEffect } from "react";
import { Alert, Row, Col, FormGroup, Input, Button, Popover, PopoverBody } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { createUser } from "../data-helpers/user-data-helpers";
import { getSchoolById, getSchoolLevelsData, getSchoolsData, updateSchoolData, getInstitutionsIndustriesData } from "../data-helpers/schools-data-helpers";
import { getPlataformCountriesData } from "../data-helpers/countries-data-helpers";

const SignUpSchool = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [error, setError] = useState(undefined);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [schoolLevels, setSchoolLevels] = useState(undefined);
  const [schools, setSchools] = useState([]);
  const [popoverPageAdminInfo, setPopoverPageAdminInfo] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(undefined);
  const [institutionsIndustries, setInstitutionsIndustries] = useState([]);

  const togglePageAdminInfo = () => setPopoverPageAdminInfo(!popoverPageAdminInfo);

  const history = useNavigate();

  const onFirstNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, first_name: event.target.value }));
    setError(undefined);
  };

  const onLastNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, last_name: event.target.value }));
    setError(undefined);
  };

  const onEmailChanged = (event) => {
    setEmail(event.target.value);

    setUser((prevState) => ({ ...prevState, email: event.target.value }));
    setError(undefined);
  };

  const onPasswordChanged = (event) => {
    setPassword(event.target.value);
    setError(undefined);
  };

  const onSchoolEmailChanged = (event) => {
    setUser((prevState) => ({ ...prevState, school_email: event.target.value }));
    setError(undefined);
  };

  const onInstitutionNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, institution_name: event.target.value }));
    setError(undefined);
  };

  const onSchoolChanged = (values) => {
    const schoolSelected = values.length === 1 ? values[0] : undefined;

    setUser((prevState) => ({ ...prevState, school: schoolSelected }));
    setError(undefined);
  };

  const onSchoolBlur = (event) => {
    const currentValue = event.target.value.trim();
    const foundSchool = schools.find((school) => school.name === currentValue);

    setUser((prevState) => ({ ...prevState, school: foundSchool }));
    setError(undefined);
  }

  const onSchoolLevelChanged = (event) => {
    const level = event.target.value;

    setUser((prevState) => ({ ...prevState, school_level: level }));
    setError(undefined);

    getSchools(level);
  };

  const onIndustryChanged = (event) => {
    const value = event.target.value;

    setUser((prevState) => ({ ...prevState, institution_industry: value }));
    setError(undefined);
  };

  const onCountryChanged = (event) => {
    const country = event.target.value;

    setUser((prevState) => ({ ...prevState, country: event.target.value }));
    setCountry(country);
    setSchools([]);
    setError(undefined);

    getSchoolLevels(country);
  };

  const getPlataformCountries = async () => {
    const data = await getPlataformCountriesData();

    setCountries(()=>[ ...data ]);
  };

  const getSchoolLevels = async (country) => {
    const data = getSchoolLevelsData(country);

    setSchoolLevels(()=>[ ...data ]);
  };

  const getSchools = async (level) => {
    const data = await getSchoolsData(country, level);
    
    if (data) {
      setSchools(()=>[ ...data ]);
    }
  };

  const getInstitutionsIndustries = async () => {
    const data = getInstitutionsIndustriesData();

    setInstitutionsIndustries(()=>[ ...data ]);
  };

  const createPageAdministratorAccount = async () => {
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);
      
    await createUser({
      'institution_name': user.institution_name,
      'institution_industry': user.institution_industry,
      'first_name': user.first_name,
      'last_name': user.last_name,
      'email': user.email,
      'school_office_email': user.school_email,
      'school_id': user.school.id,
      'role': 'school-page-administrator',
      'isCompleteProfile': false
    });

    userCredential.user.sendEmailVerification();

    await auth.signOut();
  }

  const createSchoolOfficeAccount = async () => {
    const userCredential = await auth.createUserWithEmailAndPassword(user.school_email, password);
    
    await createUser({
      'institution_name': user.institution_name,
      'institution_industry': user.institution_industry,
      'first_name': user.school.name,
      'last_name': '',
      'email': user.school_email,
      'page_administrator_email': user.email,
      'school_id': user.school.id,
      'role': 'school-office',
      'isCompleteProfile': false
    });
    
    userCredential.user.sendEmailVerification();

    await auth.signOut();
  }

  const createUserWithEmailAndPassword = async () => {
    try {
      await createPageAdministratorAccount();
      await createSchoolOfficeAccount();
      await updateSchoolData({
        'id': user.school.id,
        'page_administrator_email': user.email,
        'school_office_email': user.school_email,
        'isCompleteProfile': false
      });

      history(`${process.env.PUBLIC_URL}/verify-email`);

      return true;
    } catch (error) {
      console.log("createUserWithEmailAndPassword error:", error);
      setError(error.code === 'auth/weak-password' ? 'The password is too weak.' : error.message.replace('Firebase:', ''));
      setLoading(false);

      return false;
    }
  };

  const isSchoolOfficeEmailDomainValid = async () => {
    const schoolData = await getSchoolById(user.school.id);
    const isDomainAnEmptyValue = !schoolData.domain || schoolData.domain.length === 0;

    if (isDomainAnEmptyValue) {
      return false;
    }
    
    const emailSection = 0;
    const domainSection = 1;
    const domainParts = schoolData.domain.split('@');
    const isAnEmailValue = domainParts[emailSection].length > 0;

    if (isAnEmailValue) {
      return schoolData.domain === user.school_email;
    } else {
      const schoolEmailParts = user.school_email.split('@');
      
      return schoolEmailParts[domainSection] === domainParts[domainSection];
    }
  }

  const onSignUp = async (event) => {
    event.preventDefault();

    if (!user.institution_industry) {
      setError("Please select a valid industry.");
      return;
    }

    /* if (!user.school) {
      setError("Please select a valid school.");
      return;
    } */

    if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email))
      || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.school_email))) {
      setError("Please enter a valid email.");
      return;
    }

    if (user.email === user.school_email) {
      setError("The emails cannot be the same.");
      return;
    }

    if (/* !user.country
        ||  */user.institution_name === ""
        || user.school_email === ""
        || user.email === ""
        || user.first_name === ""
        || user.last_name === ""
        || email === ""
        || password === ""
      ) {
      setError("Please complete all the information.");
      return;
    };

    setLoading(true);

    if (await isSchoolOfficeEmailDomainValid()){
      await createUserWithEmailAndPassword();
    } else {
      setError('School office email address does not match with the database information.');
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getPlataformCountries();
    getInstitutionsIndustries();
  }, []);

  return (
    <div className="w-100 p-0 h-100">
      <Row>
      <Col md="12">
        <h6 className="text-start mt-4">Please complete your institutional information</h6>
      </Col>
     {/*  <Col md="12">
          <FormGroup>
            <Input
              type="select"
              onChange={onCountryChanged}
            >
              <option value="">
                Select school location
              </option>
              {countries && countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </Input>                               
          </FormGroup>
      </Col> */}
      {/* <Col md="12">
        <FormGroup>
          <Input
            type="select"
            onChange={onSchoolLevelChanged}
          >
            <option value="">
              Select a school level
            </option>
            {schoolLevels && schoolLevels.map((level, index) => (
              <option key={index} value={level.name}>
                {level.description}
              </option>
            ))}
          </Input>   
        </FormGroup>
      </Col> */}
      {/* <Col md="12">
        <FormGroup>
          <Typeahead
            id="school_selection"
            labelKey="name"
            options={schools}
            placeholder="Select a school"
            onChange={onSchoolChanged}
            onBlur={onSchoolBlur}
          />                             
        </FormGroup>
      </Col> */}
      <Col md="12">
        <FormGroup>
          <Input
            type="select"
            onChange={onIndustryChanged}
          >
            <option value="">
              Select an industry
            </option>
            {institutionsIndustries && institutionsIndustries.map((level, index) => (
              <option key={index} value={level.name}>
                {level.name}
              </option>
            ))}
          </Input>   
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input type="text" placeholder="Social institution name" onChange={onInstitutionNameChanged}/>
        </FormGroup>
      </Col>
      <Col md="12 text-left">
        <FormGroup>
          <Input type="email" placeholder="Office email address" onChange={onSchoolEmailChanged}/>
        </FormGroup>
      </Col>
      <Col md="12">
        <h6 className="text-start mt-4">
          Please complete your page administrator information <i className="fa fa-info-circle" id="PopoverPageAdminInfo" ></i>
        </h6>
        <Popover
          placement="right"
          isOpen={popoverPageAdminInfo}
          target="PopoverPageAdminInfo"
          trigger="hover"
          toggle={togglePageAdminInfo}
        >
          <PopoverBody>
            This will be your school’s focal point on this platform. This individual will be responsible for inputting and updating your school’s fundraising campaigns, from time to time monitoring donor industry groups and most importantly, communicating with donor leads for consideration and implementation of the wider group’s recommendations and suggestions. The administrator must also be signed up as a member of the NationBuild platform.
          </PopoverBody>
        </Popover>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input type="text" placeholder="Page Administrator First Name" onChange={onFirstNameChanged}/>
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input type="text" placeholder="Page Administrator Last Name" onChange={onLastNameChanged}/>
        </FormGroup>
      </Col>
      </Row>
      <FormGroup>
        <Input type="email" placeholder="Page Administrator Email address" onChange={onEmailChanged}/>
      </FormGroup>
      <FormGroup>
        <Input type="password" placeholder="Password" onChange={onPasswordChanged} />
      </FormGroup>
    
      { error && 
        <Alert color="danger">
          <p>{ error }</p>
        </Alert>
      }

      <Row>
        <Col sm="12">
          <FormGroup className="d-flex flex-wrap mt-3 mb-0">
            {loading ? (
              <Button color="primary d-block w-100" disabled={loading}>
                Loading...
              </Button>
            ) : (
              <Button color="primary d-block w-100" onClick={onSignUp}>
                Sign Up
              </Button>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default SignUpSchool;
