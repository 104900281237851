import React from "react";
import { Card, CardBody, Media, Col, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";

const DonorInvitationOption = (props) => {
  return (
    <Card className="mb-2">
      <div className="donor-horizontal-option">
        <CardBody className="p-4">
         <Row>
          <Col md="7" xs="12">
            <Media>
              <img
                className="img-60 img-fluid rounded"
                src={props.image}
              />
              <Media body className="px-2">
                <h6 className="f-w-600 m-0">
                  <Link
                    to={`${process.env.PUBLIC_URL}/user/profile/${props.id}`}
                  >
                    { `${props.first_name} ${props.last_name}` }
                  </Link>
                </h6>
                <p>
                  { props.industry }
                </p>
              </Media>
            </Media>
          </Col>
          <Col>
            <Row>
              { !props.accepted && props.onIgnoredClicked && <Col>
                <Button
                  color='light d-block w-100 mb-2'
                  onClick={props.onIgnoredClicked}
                >
                  Ignore
                </Button>
              </Col>
              }
              { !props.accepted && props.onAcceptedClicked && <Col>
                <Button
                onClick={props.onAcceptedClicked}
                  color='primary d-block w-100'
                >
                  Accept
                </Button>
              </Col>
              }

              { props.accepted && 
              <Col>
                <Button
                  disabled
                  color='primary d-block w-100'
                >
                  Accepted
                </Button>
              </Col>
              }

            </Row>
          </Col>
         </Row>
        </CardBody>
      </div>
    </Card>
  );
};

export default DonorInvitationOption;
