import { db } from "src/component/alumnibuild/components/firebase";
import { collection,  query, where, getDocs } from "firebase/firestore";

function getSumOfCurrentYear(payments) {
  const today = new Date();
  const currentYear = today.getFullYear();
  const sumOfCurrentYear = payments
    .filter(payment => payment.date.getFullYear() === currentYear)
    .reduce((sum, payment) => sum + payment.amount, 0);
  return sumOfCurrentYear;
}

function getDonorCount(payments){
  const userIdList = payments.map((payment) => payment.userId);
  const distincts = new Set(userIdList);
  
  return distincts.size;
}

function getWeeklyPayments(payments) {
  const currentDate = new Date(); 
  const currentYear = currentDate.getFullYear(); 

  const weeklyPayments = {};

  payments.forEach(payment => {
    const paymentDate = new Date(payment.date);
    const paymentYear = paymentDate.getFullYear();

    if (paymentYear === currentYear) {
      const paymentWeek = getWeekStartDate(paymentDate);
      const paymentAmount = payment.amount;

      if (weeklyPayments.hasOwnProperty(paymentWeek)) {
        weeklyPayments[paymentWeek] += paymentAmount;
      } else {
        weeklyPayments[paymentWeek] = paymentAmount;
      }
    }
  });

  const result = Object.entries(weeklyPayments).map(([week, total]) => ({
    date: week,
    total: total
  }));

  return result;
}

function getWeekStartDate(date) {
  const startDate = new Date(date);
  startDate.setDate(date.getDate() - date.getDay()); 
  startDate.setHours(0, 0, 0, 0);
  return startDate.toISOString().slice(0, 10); 
}

export async function getSchoolAnalytics(schoolId) {
  const reference = collection(db, "payments");
  const snapshot = await getDocs(
    query(
      reference,
      where('schoolId', "==", schoolId)
    )
  );
      
  let analytics = {
    'currentYear': new Date().getFullYear(),
    'year': 0,
    'donorCount': 0,
    'weeklyBasis': {
      'weeks': [],
      'total': []
    }
  }
  
  if (snapshot.size !== 0) {
    let payments = [];

    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const data = doc.data();
      
      payments.push({
        userId: data.userId,
        amount: data.donation * 1,
        date: new Date(data.created)
      })
    }

    const weeklyPayments = getWeeklyPayments(payments);

    analytics = {
      ...analytics,
      'year': getSumOfCurrentYear(payments),
      'donorCount': getDonorCount(payments),
      'weeklyBasis': { 
        'weeks':  weeklyPayments.map((week)=> new Date(week.date).toISOString()),
        'total': weeklyPayments.map((week)=>week.total)
      }
    }
  } 

  return analytics;
}


