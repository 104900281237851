import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container } from "reactstrap";
import AlumniProfile from "./alumni-profile";
import SchoolProfile from "./school-profile";
import { getCurrentAuthUser } from "../data-helpers/user-data-helpers";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";

const UserEdit = (props) => {
  const history = useNavigate();
  const [user, setUser] = useState(null);

  const getUser = async () => {
    if (user) return;
    const data = await getCurrentAuthUser();

    if (data) {
      setUser(data);
    } else {
      console.log("User not found.");
      history(`${process.env.PUBLIC_URL}/login`);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Fragment>
      <Breadcrumb title="Profile" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
          { user && user.role === "alumni" &&
            <AlumniProfile { ...user } />
          }

          { user && user.role === "school" &&
            <SchoolProfile { ...user } />
          }
        </Col>
       </Row>
      </Container>
    </Fragment>
  );
};

export default UserEdit;
