import React from "react";
import { Card, CardHeader, Media, Col, Button, CardFooter } from "reactstrap";

const DonorSmallProfile = (props) => {
  const onActionClicked = () => {
    if (props.action.callback) {
      props.action.callback();
    }
  }

  const getActionColor = () => {
    return props.action.color ? props.action.color: 'primary';
  }

  return (
    <div className="donor-small-profile custom-card m-0 h-100 d-flex flex-column">
      <CardHeader className="background-image-container"/>
      <div className="card-profile">
        <Media
          body
          className="rounded-circle"
          src={props.image}
        />
      </div>
      {/* <ul className="card-social">
        <li>
          <a href={ props.linkedin || 'https://www.linkedin.com/' } target="_blank">
            <i className="fa fa-linkedin"></i>
          </a>
        </li>
      </ul> */}
      <div className="profile-details px-3">
        <div className="title font-weight-bold">
          <a href={props.url}>
            { `${props.first_name} ${props.last_name}` }
          </a>
        </div>
        { props.country && <div className="py-1">
          <i className="fa fa-map-marker"></i> <span>{ props.country }</span>
        </div> }
        { props.country_of_origin && <div>
          <span className="txt-primary">Country of origin:</span> { props.country_of_origin }
        </div> }
        <div>
          <span className="txt-primary">Industry:</span> { props.industry }
        </div>
        { props.looking_to && props.looking_to.length > 0 && <div>
          <span className="txt-primary">Looking to:</span> { props.looking_to.join(", ") }
        </div> }
        { props.interest.length > 0 && <div>
          <span className="txt-primary">Interests:</span> { props.interest.join(", ") }
        </div> }
      </div>
      { props.action &&
        <CardFooter className="row mt-auto">
          <Col className="m-2">
            <Button
              color={`${getActionColor()} d-block w-100`}
              disabled={props.action.isDisabled}
              onClick={onActionClicked}
            >
              { props.action.label }
            </Button>
          </Col>
        </CardFooter>
      } 
    </div>
  );
};

export default DonorSmallProfile;
