import React, { useState, useEffect } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

const CarouselWidget = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const next = () => {
    if (animating) return;

    const nextIndex = activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;

    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;

    const nextIndex = activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;

    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  return (
    <Carousel
      interval={3000}
      ride="carousel"
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        className='home__carousel-indicator'
        items={props.items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      { props.items && props.items.map((item) => {
          return (
            <CarouselItem
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              key={item.src}
            >
              <div style={{
                backgroundImage: `url(${item.src})`,
                backgroundPosition: '50%',
                backgroundSize: 'cover',
                height: '700px'
              }}>
                <div className="home__hero-section">
                  <div className="home__hero-image">
                      <h1 className="home__hero-title mb-3 mt-0">
                        <span className="home__hero-title--variation">Connecting</span> Thinkers and Doers { !isMobileScreen && <br /> }<span className="home__hero-title--variation">in the Diaspora and at Home for</span> Good
                      </h1>
                    <a className="home__button home__button--call-out home__button--warning btn mb-4" href="/signup" target="_blank">Join now</a>
                  </div>
                </div>
              </div>
            </CarouselItem>
          );
        })
      }
    </Carousel>
  );
}

export default CarouselWidget;