import React from "react";
import {  Form } from "reactstrap";
import { Link } from "react-router-dom";

const VerifyEmailActionHandler = () => {
  return (
    <Form className="w-100">
      <div className="logo-wrapper">
        <a href="/">
          <img src={require("../../../assets/images/logo/nationbuild-icon.png")}/>
        </a>
      </div>
      <div className="pb-4 pt-4">
        <h4>Email has been verified</h4>
      </div>
      <hr />
      <p className="text-justify pt-4 f-14" style={{ textAlign: "justify"}}> 
        Thank you, your email has been verified. Your account is now active.
        Please use the link below to redirect to the login page.
      </p>

      <div className="w-100">
        <Link color="primary d-block w-100" to={`${process.env.PUBLIC_URL}/login`}>
          Go to Login
        </Link>
      </div>
    </Form>
  );
};

export default VerifyEmailActionHandler;
