export class ConfigDB {
  static data = {
    settings: {
      layout_type: "ltr",
      sidebar: {
        type: "default",
      },
      customizer_visible: false
    },
    color: {
      layout_version: "light",
      color: 'color-6', 
      primary_color: '#126bc5', 
      secondary_color: '#f2f3f5', 
      mix_layout: "color-only",
    },
    router_animation: "fade",
  };
 
 
}

export default ConfigDB;
