import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Media, Table, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { getCurrentAuthUser } from "../data-helpers/user-data-helpers";
import { getUserPayments } from "../data-helpers/payments-data-helpers";
import { getTimeDifferenceFromNow } from "../utils/date-utils";
import SchoolSmallProfile from "./school-small-profile";
import DonorSmallProfile from "./donor-small-profile";
import { getRegisteredSchoolsDataFiltered} from "../data-helpers/schools-data-helpers";
import { getPlataformCountriesData } from "../data-helpers/countries-data-helpers";
import { getDonorListBaseOnInterest } from "../data-helpers/alumni-data-helpers";
import { createDonorConnection } from "../data-helpers/donor-connections-data-helpers";
import { getUserNotificationsCount } from "../data-helpers/notifications-data-helpers";

const AlumniDashboard = (props) => {
  const currentUserLoggedId = localStorage.getItem("userId");
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [schools, setSchools] = useState([]);
  const [donorsToConnect, setDonorsToConnect] = useState([]);
  const [userName, setUserName] = useState(localStorage.getItem("username"));
  const [donationsData, setDonationsData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [ userNotificationsCount, setUserNotificationsCount ] = useState(0);
  const [isReady, setIsReady] = useState(false);

  const toggle = () => {
    setModal(!modal);
    localStorage.setItem("isUser", null);
  };
  const history = useNavigate();

  const getData = async () => {
    /* await loadCampaigns();
    await loadDonations(); */
    await loadDonors();
    await getNotificationsData();

    setIsReady(true);
  }

  const getNotificationsData = async () => {
    const data = await getUserNotificationsCount(currentUserLoggedId);

    setUserNotificationsCount(data);
  }

  const loadDonations = async () => {
    const user = await getCurrentAuthUser();
    //TODO: These metrics or information need to be paginated or previously calculated
    const donationsData = await getUserPayments(user.id);

    setDonationsData(() => [...donationsData]);
  }

  const loadDonors = async () => {
    const data = await getDonorListBaseOnInterest(currentUserLoggedId);
    const donors = data.map((donor, i) => {
        return {
          ...donor,
          url:`${process.env.PUBLIC_URL}/user/profile/${donor.id}`,
          action: {
            'label': 'Connect',
            'color': 'primary',
            'isDisabled': false,
            'callback': () => onDonorConnectClicked(donor, i)
          }
        }
    });

    setDonorsToConnect(() => [ ...donors]);
  }

  const getSchoolsDataWithCountryName = async (schools) => {
    const countries = await getPlataformCountriesData();

    return schools.map((school) => { 
      const countryFound = countries.find((country) => country.id === school.country);

      return {
        ...school,
        'countryName': countryFound ? countryFound.name : ''
      }
    });
  }
  const loadCampaigns = async () => {
    const schoolsData = await getRegisteredSchoolsDataFiltered([]);
    
    if(!schoolsData) {
      return;
    } 
    
    const schoolsDataWithCountryName = await getSchoolsDataWithCountryName(schoolsData); // TODO: these should be part of que database query
    
    setSchools(schoolsDataWithCountryName);
  }
  
  const onDonationClicked = (id) => {
    history(`${process.env.PUBLIC_URL}/campaign/donate/receipt/${id}`);
  }

  const onDonorConnectClicked = async (donor) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const action = {
      'label': 'Pending',
      'color': 'primary',
      'isDisabled': true,
      'callback': null
    };

    setDonorsToConnect(current => {
      return current
        .map(item => (item.id === donor.id  ? { ...item, 'action': action } : item))
    });

    await createDonorConnection({
      'fromUserId': currentUserLoggedId,
      'toUserId': donor.id
    });

    setIsProcessing(false);
  }

  useEffect(() => {
    const interval = setInterval(() => { getNotificationsData(); }, 5000);

    getData();
    setIsMobileScreen(window.innerWidth <= 767);

    return () => {
      clearInterval(interval);
    }

  }, []);
  
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="" />
      <Container fluid={true}>
        <Row>
          <Col lg="12 xl-100">
            <Row>
              <Col>
                <Card className="gradient-primary o-hidden">
                  <CardBody className="tag-card">
                    <div className="default-chart f-30">
                     Hello, {userName}
                    </div>
                    <div className="default-chart">
                     Welcome to your dashboard
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="2" md="3">
                <Card className="o-hidden btn-secondary">
                  <CardBody className="tag-card text-center p-4">
                    <div className="default-chart d-flex justify-content-center align-items-center p-3" >
                      <div className="p-0  ">
                      { isReady ? 
                        <Link to={`${process.env.PUBLIC_URL}/notifications`} className="txt-dark">
                          <i className="fa fa-bell txt-primary"></i><br /><b> You have {userNotificationsCount} unread notfications</b>
                        </Link>
                      : 
                      <Link to={`${process.env.PUBLIC_URL}/notifications`} className="txt-dark">
                        <i className="fa fa-bell txt-primary"></i><br /><b> You have ... unread notfications</b>
                      </Link>
                      }
                        
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xl="12 xl-100 box-col-12">
            <Card>
              <CardHeader className="no-border py-4">
                <h5>Top connection picks for you</h5>
              </CardHeader>
              <CardBody className="pt-0  py-4">
                <div className="setting-dot">
                  <div className="setting-bg-primary bg-white position-set pull-right">
                    <i className="icofont icofont-gear fa fa-spin fa-cog"></i>
                  </div>
                </div>
                <Row>
                  {isReady && donorsToConnect.length === 0 ? <div className="text text-muted">No connections found.</div> : ''}
                  <Row className="d-flex flex-wrap">
                    {donorsToConnect.map((properties, i) => {
                      return (
                        <Col md="3" sm="12" className={`${!isMobileScreen ? 'd-flex ': 'mb-4'} mt-sm-2 justify-content-start flex-column`} key={i}>
                        <DonorSmallProfile {...properties}/>
                        </Col>
                      );
                    })}
                  </Row>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* <Col xl="12 xl-100 box-col-12">
            <Card>
              <CardHeader className="no-border py-4">
                <h5>My Community Hubs</h5>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="setting-dot">
                  <div className="setting-bg-primary bg-white position-set pull-right">
                    <i className="icofont icofont-gear fa fa-spin fa-cog"></i>
                  </div>
                </div>
                <Row>
                  {isReady && <div className="text text-muted">No hubs found.</div>}
                </Row>
              </CardBody>
            </Card>
          </Col> */}

          {/* <Col xl="12 xl-100 box-col-12">
            <Card>
              <CardHeader className="no-border py-4">
                <h5>Causes I support</h5>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="setting-dot">
                  <div className="setting-bg-primary bg-white position-set pull-right">
                    <i className="icofont icofont-gear fa fa-spin fa-cog"></i>
                  </div>
                </div>
                <Row>
                  {isReady && <div className="text text-muted">No causes found.</div>}
                </Row>
              </CardBody>
            </Card>
          </Col> */}

          {/* <Col xl="12 xl-100 box-col-12">
            <Card>
              <CardHeader className="no-border py-4">
                <h5>My Donation overview</h5>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="setting-dot">
                  <div className="setting-bg-primary bg-white position-set pull-right">
                    <i className="icofont icofont-gear fa fa-spin fa-cog"></i>
                  </div>
                </div>
                <div className="activity-table table-responsive recent-table">
                  {isReady && donationsData.length === 0 ? <div className="text text-muted">No donations found.</div> : ''}
                  <Table borderless>
                    <tbody>
                      {donationsData.map((donation, i) => {
                        return (<tr key={i}>
                        <td>
                          <div className="recent-images">
                            <Media className="img-fluid" style={{ height:'50px' }} src={require("../../../assets/images/school-profile-image.png")} alt="" />
                          </div>
                        </td>
                        <td>
                          <h5 className="default-text mb-0 f-w-700 f-18">{ donation.campaignTitle }</h5>
                        </td>
                        <td>
                          <span className="badge rounded-pill recent-badge f-12" style={{ minWidth:'100px' }} >{getTimeDifferenceFromNow(donation.created)}</span>
                        </td>
                        <td className="f-w-700">{ donation.donation } USD</td>
                        <td>
                          <span className="badge rounded-pill recent-badge">
                          <Button color="pill" onClick={() => onDonationClicked(donation.id)}>View</Button>
                          </span>
                        </td>
                      </tr>
                      );
                    })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}

        </Row>
        <Modal isOpen={modal} className="welcome-popup modal-dialog-centered">
          <button onClick={toggle} className="btn-close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <ModalBody>
            <ModalHeader></ModalHeader>
            <div className="contain p-30">
              <div className="text-center">
                <h3>Welcome to Poco admin</h3>
                <p>start your project with developer friendly admin </p>
                <button onClick={toggle} className="btn btn-primary btn-lg txt-white" type="button" data-bs-dismiss="modal" aria-label="Close">
                  Get Started
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default AlumniDashboard;
