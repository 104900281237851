import React, { Fragment, useEffect, useState, useCallback  } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import debounce from 'lodash.debounce';
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import SchoolSmallProfile from "../school-small-profile";
import DonorSmallProfile from "../donor-small-profile";
import ExplorerFilters from "./explorer-filters";
import { getRegisteredUsersDataFiltered } from "../../data-helpers/alumni-data-helpers";
import { getPlatformOptionsData } from "../../data-helpers/platform-options-data-helpers";
import { createDonorConnection } from "../../data-helpers/donor-connections-data-helpers";

const Explorer = (props) => {
  const currentUserLoggedId = localStorage.getItem("userId");
  const [schools, setSchools] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [donorsToConnect, setDonorsToConnect] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const history = useNavigate();

  const getInitialData = async () => {
    const platformOptionsData = await getPlatformOptionsData();

    setPlatformOptions(platformOptionsData);

    await onFilterChanged({});
    setIsReady(true);
  }

  const onFilterChanged = async (filters) => {
    setFilters(current => {
      return { ...current, ...filters };
    });

    const data = await getRegisteredUsersDataFiltered(currentUserLoggedId, filters);

    await loadDonors(data);
  }

  const loadDonors = async (data) => {
    const donors = data.map((donor, i) => {
        let actionsProps = {};

        if (donor.isAlreadyAConnection) {
          actionsProps = {
              'label': 'Message',
              'callback': () => { history(`${process.env.PUBLIC_URL}/my-connections`) }
            }
        } else {
          actionsProps = {
            'label': 'Connect',
            'callback': () => onDonorConnectClicked(donor, i)
          }
        }

        return {
          ...donor,
          url:`${process.env.PUBLIC_URL}/user/profile/${donor.id}`,
          action: {
            ...actionsProps,
            'color': 'primary',
            'isDisabled': false,
          }
        }
    });

    setDonorsToConnect(donors);
  }

  const debouncedFilterChangedHandler = useCallback(
    debounce(onFilterChanged, 500)
  , []);

  const onDonorConnectClicked = async (donor) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const action = {
      'label': 'Pending',
      'color': 'primary',
      'isDisabled': true,
      'callback': null
    };

    setDonorsToConnect(current => {
      return current
        .map(item => (item.id === donor.id  ? { ...item, 'action': action } : item))
    });

    await createDonorConnection({
      'fromUserId': currentUserLoggedId,
      'toUserId': donor.id
    });

    setIsProcessing(false);
  }

  useEffect(() => {
    getInitialData();

    setIsMobileScreen(window.innerWidth <= 767);
  }, []);


  return (
    <Fragment>
      <Breadcrumb parent="" title="Explore" />
      <Container fluid={true}>
        <Row>
          <ExplorerFilters
            platformOptions={platformOptions}
            onFilterChanged={debouncedFilterChangedHandler}
          />

          <Col md="8" xl="8">
          <Card>
            <Row>
              {isReady && donorsToConnect.length === 0 ? <div className="text text-muted">No results found.</div> : ''}

              {donorsToConnect.map((properties, i) => {
                return (
                  <Col xl="4" md="4" sm="6" xs="12" className={`${!isMobileScreen ? 'd-flex ': 'mb-4'} mt-sm-2 justify-content-start flex-column`} key={i}>
                    <DonorSmallProfile {...properties}/>
                  </Col>
                );
              })}
            </Row>
           </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Explorer;
