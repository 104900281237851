import { db } from "src/component/alumnibuild/components/firebase";
import { collection, addDoc, doc, updateDoc, where, query, getDocs} from "firebase/firestore";
import { getTimeDifferenceFromNow } from "../utils/date-utils";
import { addRequestConnectionNotification } from "./notifications-data-helpers";
import { sortByProperty } from "../utils/array-utils";

export async function saveChatMessage(data) {
  if (data.id) {
    return updateChatMessage(data);
  }

  await addRequestConnectionNotification();

  return createChatMessage(data);
}

export async function updateChatMessage(data) {
  const reference = doc(db, "chat", data.id);

  return updateDoc(reference, { ...data, 'updated': new Date().toISOString() } );
}

export async function createChatMessage(data) {
  const reference = collection(db, 'chat');

  return addDoc(reference, {
    "users": data.users,
    "messages": data.messages,
    'created': new Date().toISOString()
  });
}

export async function getUserChats(currentUserId) {
  const reference = collection(db, 'chat');
  const queryFilter = query(reference, where("users", "array-contains", currentUserId));
  const snapshot = await getDocs(queryFilter);
  
  let result = [];
  
  if (snapshot.size > 0) {
    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const data = doc.data();
      const userListFiltered = data.users.filter((id)=> id !== currentUserId); // Todo: check this
      const contactedUserId = userListFiltered[0];

      result.push({
        ...data,
        'id': doc.id,
        'contactedUserId': contactedUserId,
        'messages': data.messages.map((m) => ({ ...m, 'timeFromNow': getTimeDifferenceFromNow(m.time) }))
      });

      
    }

    const isStringDateProperty = true;

    result = sortByProperty(result, "updated", isStringDateProperty);
  } 
  
  return result;
}
