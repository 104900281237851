import React, { useState, useRef, useEffect } from 'react';
import { FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

async function scaleImageBeforeUpload(file, width, height) {
  if (!file.type.match(/image.*/)) return null;

  const image = new Image();
  image.src = URL.createObjectURL(file);

  await new Promise((res) => image.onload = res);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d", {alpha: true});

  canvas.width = width;
  canvas.height = height;

  if (image.height <= image.width) {
      const scaleProportions = canvas.height / image.height;
      const scaledWidth = scaleProportions * image.width;
      context.drawImage(image, (canvas.width - scaledWidth)/2, 0, scaledWidth, canvas.height);
  }
  else {
      const scaleProportions = canvas.width / image.width;
      const scaledHeight = scaleProportions * image.height;
      context.drawImage(image, 0, (canvas.height - scaledHeight)/2, canvas.width, scaledHeight);
  }

  return canvas;
}

const ImageCropper = (props) => {
  const [image, setImage] = useState('');
  const [previewUrl, setPreviewUrl] = useState(props.image || undefined);

  const cropperRef = useRef(null);

  const onChange = (cropper) => {
    try {
      if (cropper) {
        const newImage = cropper.getCanvas({ height: props.height, width: props.width,})?.toDataURL();
        
        if (newImage) {
          props.imageChanged(newImage);
          setPreviewUrl(newImage);
        }
      }
    } catch (error) {
      console.log("onChange error:", onChange)
    }
  };

  const onLoadImage = async (event) => {
      const { files } = event.target;

      if (files && files[0]) {
        const scaledImage = await scaleImageBeforeUpload(files[0], props.width * 2, props.height * 2);
        const blob = scaledImage.toDataURL();

        props.imageChanged(blob);
        setPreviewUrl(blob);

        setImage(blob);
      }

      event.target.value = '';
  };

  useEffect(() => {
      return () => {
        if (image) {
          URL.revokeObjectURL(image);
        }
      };
  }, [image]);

  return (
    <div className='image-cropper'>
      <div className="Crop-Controls mb-4">
        <input className="form-control" type="file" accept="image/*" onChange={onLoadImage} />
      </div>
      {Boolean(image) && (
        <FixedCropper
            ref={cropperRef}
            className="upload-example__cropper mb-4"
            src={image}
            stencilSize={{
              width: props.width,
              height: props.height,
            }}
            imageRestriction={ImageRestriction.stencil}
            onChange={onChange}
          />
      )}
      <div>
        {Boolean(previewUrl) && (
          <img
            alt="Crop preview"
            src={previewUrl}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              height: props.height,
            }}
          />
        )}
      </div>
    </div>
  );
};
export default ImageCropper;