import React, { useState } from "react";
import { Alert, Container, Row, Col, CardBody, Form, FormGroup, Input, Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./firebase";
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, Link } from "react-router-dom";
import { getSchoolLastCampaign } from "../data-helpers/campaign-data-helpers";
import { getUserDataByEmail } from "../data-helpers/user-data-helpers";
import { log } from "../data-helpers/logs-data-helpers";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState({});

  const history = useNavigate();

  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    setIsVerified(true);
  };

  const isSchoolOfficeEmailVerified = async (user) => {
    //TODO: School office data need to be independent, Page admin and school share same password
    const schoolOfficeUserCredential = await auth.signInWithEmailAndPassword(user.school_office_email, password);
    const isEmailVerified = schoolOfficeUserCredential.user.emailVerified;

    await auth.signOut();

    return isEmailVerified;
  };
  const signInWithEmailAndPassword = async (user) => {
    
    if (user.role === 'school-page-administrator') {
      const isSchoolVerified = await isSchoolOfficeEmailVerified(user);

      if (!isSchoolVerified) {
        throw new Error("School office email needs to be verified first.");
      }
    }

    const userCredential = await auth.signInWithEmailAndPassword(email, password);

    if (!userCredential.user.emailVerified) {
      throw new Error("Please verify your email account first");
    }

    return true;
  };

  const onLoginPressed = async (event) => {
    event.preventDefault();

    if (!isVerified) {
      setError(`Please verify that you are not a robot. Click the checkbox`);
      return;
    };

    if (loading || email === "" || password === "") {
      return;
    };

    setLoading(true);

    try {
      const userData = await getUserDataByEmail(email);
      
      if (!userData) {
        throw new Error("User not found in the database.");
      }
      
      setUser(userData);

      const signWithSuccess = await signInWithEmailAndPassword(userData);
      
      if (signWithSuccess) {
        await redirectToApp(userData);
      }
    } catch (error) {
      console.log("Login error:", error);

      await log(`Login error: ${error.message ? error.message : JSON.stringify(error)}`);

      setError(error.code === 'auth/wrong-password' ? 'Wrong password.' : error.message.replace('Firebase:', ''));
      setLoading(false);
    }
  };

  const redirectToApp = async (user) => {
    if (user) {
      let url;

      if (user.role === 'school-office' || user.role === 'school-page-administrator') {

        const schoolCampaign = await getSchoolLastCampaign(user.school_id);
        const hasActiveCampaign = schoolCampaign ? schoolCampaign.isActive : false;

        localStorage.setItem("showCompleteProfileModal", !hasActiveCampaign);
        localStorage.setItem("userSchoolId", user.school_id);

        url = hasActiveCampaign ? `${process.env.PUBLIC_URL}/dashboard` : `${process.env.PUBLIC_URL}/campaigns/new`
      } else {
        localStorage.setItem("showCompleteProfileModal", !user["isCompleteProfile"] || false);

        url = user["isCompleteProfile"] ? `${process.env.PUBLIC_URL}/dashboard` : `${process.env.PUBLIC_URL}/user/edit/profile`;
      }

      localStorage.setItem("userRole", user.role);
      localStorage.setItem("userId", user.id);
      localStorage.setItem("username", `${user.first_name} ${user.last_name}`);
      localStorage.setItem("login", true);
      localStorage.setItem("userimage", user.image ? user.image: undefined);

      history(url);
    } else {
      console.log("User not found.");
      history(`${process.env.PUBLIC_URL}/login`);
    }
  }

  return (
    <div className="login page-wrapper">
      <Container fluid={true} className="p-0">
        <div className="authentication-main m-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="d-flex h-100">
                    <div className="text-center b-light p-3">
                      <Form className="w-100">
                        <div className="logo-wrapper">
                          <a href="/">
                            <img src={require("src/assets/images/logo/nationbuild-icon.png")}/>
                          </a>
                        </div>
                        <div className="pb-4 pt-4">
                          <h4>Login</h4>
                        </div>
                        <FormGroup >
                          <Input className="form-control" type="email" placeholder="Enter your email address" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                          <Input className="form-control" type="password" placeholder="Password" name="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </FormGroup>
                        <FormGroup >
                          <ReCAPTCHA
                            sitekey="6Lfwy_UnAAAAAEOGCjcAjWpv6_HWudoPkdgmYgr2"
                            onChange={handleRecaptchaChange}
                            badge="inline"
                          />
                        </FormGroup>
                        <FormGroup className="text-end">
                          <Link to={`${process.env.PUBLIC_URL}/forgot-password`} className="text-end">Forgot password?</Link>
                        </FormGroup>

                        { error && 
                          <Alert color="danger">
                            <p>{ error }</p>
                          </Alert>
                        }

                        <FormGroup className="d-flex flex-wrap mt-3 mb-0">
                          <Button color="primary d-block w-100" onClick={onLoginPressed}>
                            {loading ? 'Loading...' : 'Log in' }
                          </Button>
                        </FormGroup>
                        <div className="text-center">
                          or
                        </div>
                        <FormGroup className="d-flex flex-wrap mt-3 mb-0">
                          <div>
                            Need to create an account? <Link to={`${process.env.PUBLIC_URL}/signup`}>Sign up</Link>
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Login;
