import React, { Fragment, useEffect, useState } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import Sidebar from "./component/common/sidebar/sidebar";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import ThemeCustomize from "./component/common/theme-customizer/themeCustomize";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation } from "react-router-dom";
import ConfigDB from "./data/customizer/config";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import  {auth}  from "./component/alumnibuild/components/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Home from './component/alumnibuild/public-site/home';

import { DatabaseProvider } from "./component/alumnibuild/components/DatabaseContext";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

const App = () => {
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade';
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useNavigate();
  const toggle = () => {
    localStorage.setItem("showCompleteProfileModal", false);

    setModal(false);
  };

  const checkModalOfCompleteProfile = () => {
    const showCompleteProfileModal = localStorage.getItem("showCompleteProfileModal") === 'true' ? true : false;

    setModal(showCompleteProfileModal);

    if (showCompleteProfileModal) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "scroll";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    checkModalOfCompleteProfile();

  }, [location.pathname, modal, openMenu]);

  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return <Home />;
  }

  const onCompleteProfilePressed = async (event) => {
    event.preventDefault();

    localStorage.setItem("showCompleteProfileModal", false);

    setModal(false);

    if ((userRole === 'school-office' || userRole === 'school-page-administrator') && location.pathname !== '/campaigns/new') {
      history(`${process.env.PUBLIC_URL}/campaigns/new`);
    } else if (userRole === 'alumni' && location.pathname !== '/user/edit/profile') {
      history(`${process.env.PUBLIC_URL}/user/edit/profile`);
    }
  }

  return (
    <DatabaseProvider>
    <Fragment>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header  />
          <Sidebar />
          <Rightsidebar />
          <div className="page-body">
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={100}
                classNames={animation}
                unmountOnExit
              >
                <div>
                  <Outlet />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />
          <ThemeCustomize />
        </div>
      </div>
      <ToastContainer />

      <Modal isOpen={modal} className="welcome-popup modal-dialog-centered">
          <button onClick={toggle} className="btn-close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <ModalBody>
            <ModalHeader></ModalHeader>
            <div className="contain p-30">
              <div className="text-center">
                <h3>Welcome to NationBuild</h3>
                { userRole === 'alumni' 
                  ? <p>Start by completing your profile to begin connecting with nationbuilders from diverse backgrounds, as well as like-minded thinkers and doers like yourself!</p>
                  : <p>Start by completing your profile to discover all the features we have for you</p>
                }
                <button onClick={onCompleteProfilePressed} className="btn btn-primary btn-lg txt-white" type="button" data-bs-dismiss="modal" aria-label="Close">
                  Get Started
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

    </Fragment>
    </DatabaseProvider>
  );
};

export default App;
