import { storage } from "../components/firebase";
import { ref, getDownloadURL, uploadBytes, uploadString } from "firebase/storage";

export async function saveFile(path, file) {
  const storageRef = ref(storage, path);

  if (typeof file === 'string') {
    const dataPart = file.replace(/^data:image\/\w+;base64,/, "");
    const metadata = { cacheControl: 'public, max-age=0', contentType: 'image/jpeg' };

    return uploadString(storageRef, dataPart, 'base64', metadata);
  } else {
    return uploadBytes(storageRef, file);
  }

}

export async function getFile(path) {
  try {
    const storageRef = ref(storage, path);

    return await getDownloadURL(storageRef);
  } catch (error) {
    return undefined;
  } 
}
