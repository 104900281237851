import React, { useState, useEffect } from "react";

const Loader = (props) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1000);
  }, [show]);

  return (
    <div className={`loader-wrapper ${show ? "" : "loderhide"}`}>
       <img src={require("../../../assets/images/logo/nationbuild-icon.png")} alt="" />
    </div>
  );
};

export default Loader;
