import React, { useEffect, useState } from "react";
import { CardBody, Row, Col, Media, Label, Input } from "reactstrap";

const CampaignDonateInitialStep = (props) => {
  const [schoolData, setSchoolData] = useState({ ...props.schoolData });
  const [campaignData, setCampaignData] = useState({ ...props.campaignData });
  const [paymentMethod, setPaymentMethod] = useState('paypal-method');
  const [donation, setDonation] = useState(0.00);
  const [totalDonation, setTotalDonation] = useState(0.00);
  const [fees, setFees] = useState(0.00);
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true);
  const [isDonationPrivate, setIsDonationPrivate] = useState(false);

  const onDonationChanged = (event) => {
    const onlyNumberRegex = /^$|^[0-9]+$/;

    if (!onlyNumberRegex.test(event.target.value)) {
      return;
    }

    // TODO: validate value
    // TODO: define on what depends the fee
    const feePercentage = 3;
    const donationValue = 1 * event.target.value;
    const feesValue = ((donationValue * feePercentage) / 100);
    const totalDonationValue = donationValue + feesValue;

    setDonation(donationValue);
    setFees(feesValue);
    setTotalDonation(totalDonationValue);
  }

  const runValidations = () => {
    const isEnabled = totalDonation > 0
      && paymentMethod.length > 0;

    setIsContinueButtonDisabled(!isEnabled);
  };

  const isContinueButtonPressed = () => {
    props.completedStep({
      donation: donation,
      fees: fees,
      totalDonation: totalDonation,
      paymentMethod: paymentMethod,
      isDonationPrivate: isDonationPrivate,
    });
  }

  useEffect(() => {
    runValidations();
  }, [totalDonation, paymentMethod]);

  return (
    <Row>
    <Col sm="12">
      <CardBody>
        <div className="cont b-light p-4">
          <Row className="row w-100 p-0 m-0 col-md-12 txt-dark p-1 b-r-5">
            <Col md="3" xs="12">
              <Media
                className="img-fluid"
                src={campaignData.image}
                data-intro="This is Profile image"
              />
            </Col>
            <Col md="9" xs="12">
              <div className="col-md-12 pt-3">
                <div className="f-16 text f-w-600">You're supporting <span className="f-w-700">{schoolData.name} </span></div>
              </div>
              <div className="col-md-12 mt-0">
                <div className="f-14 text f-w-500">Your donation will benefit the campaing <span className="f-w-700">{campaignData.title}</span></div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col-md-12 mt-4">
              <Label className="form-label f-w-700">Enter your donation (USD $)</Label>
              <Input
                className="form-control digits f-w-700 text-end"
                style={{ fontSize: '40px' }}
                value={donation}
                onChange={onDonationChanged}
                autoFocus
                />
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-4">
              <Label className="form-label f-w-700 p-0">Payment method</Label>
                <Row>
                  <Col>
                    <Label className="d-block form-control" for="paypal-method">
                      <Input
                        className="radio_animated"
                        id="paypal-method"
                        type="radio"
                        name="paypal-method"
                        onChange={() => setPaymentMethod('paypal-method')}
                        checked={paymentMethod === 'paypal-method'}
                      />
                      <i className="fa fa-paypal p-3"></i>
                      Paypal
                      
                    </Label>
                  </Col>
                </Row>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-4">
              <Label className="d-block form-label" for="hide-donor-name">
                <Input
                  className="checkbox_animated"
                  id="hide-donor-name"
                  type="checkbox"
                  onChange={(event) => setIsDonationPrivate(event.target.checked)}
                />
                Don't display my name publicly on the fundraiser.
              </Label>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-4">
              <Label className="form-label f-w-700">Your donation</Label>
            </div>
          </Row>
          <Row>
            <div className="col">
              <Label className="form-label f-w-500 text-muted">Your donation</Label>
            </div>
            <div className="col text-end">
              <Label className="form-label f-w-500 text-muted">${donation}</Label>
            </div>
          </Row>
          <Row>
            <div className="col">
              <Label className="form-label f-w-500 text-muted">Fees</Label>
            </div>
            <div className="col text-end">
              <Label className="form-label f-w-500 text-muted">${fees}</Label>
            </div>
          </Row>
          <Row>
            <div className="col">
              <Label className="form-label f-w-600">Total due today</Label>
            </div>
            <div className="col text-end">
              <Label className="form-label f-w-500 text-muted">${totalDonation}</Label>
            </div>
          </Row>
          <Row className="mt-2">
            <div className="col-12 mt-4">
              <button
                className="btn btn-primary d-block w-100"
                type="button"
                disabled={isContinueButtonDisabled}
                onClick={isContinueButtonPressed}
              >
                Continue
              </button>
            </div>
          </Row>
          <Row className="text-muted f-12">
            <div className="col-md-12 mt-4">
              <Label className="form-label p-0 m-0">By continuing, you agree with NationBuild <a href="">terms</a> and <a href="">privacy notice</a>.</Label>
            </div>
            <div className="col-md-12">
              <Label className="form-label p-0">Learn more about <a href="">fees</a>.</Label>
            </div>
          </Row>
        </div>
      </CardBody>
    </Col>
    </Row>
  );
};

export default CampaignDonateInitialStep;
