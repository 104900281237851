import { db } from "src/component/alumnibuild/components/firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { getCurrentAuthUser } from "./user-data-helpers";

export async function log(message, level = "ERROR") {
  const reference = collection(db, 'logs');
  const user = await getCurrentAuthUser();

  return addDoc(reference, {
    'created': Timestamp.now(),
    'level': level,
    'message': message,
    'userId': user.id
  });
}