import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Form, Button } from "reactstrap";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import VerifyEmailActionHandler from "./verify-email-action-handler";
import ResetPasswordActionHandler from "./reset-password-action-handler";

const EmailActionHandler = () => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [actionHandlerType, setActionHandlerType] = useState(null);
  const [error, setError] = useState(null);
  const [actionCode, setActionCode] = useState(null);
  const history = useNavigate();

  const handleVerifyEmail = (oobCode) => {
    auth.applyActionCode(oobCode)
    .then((resp) => {
      setIsEmailVerified(true);
    }).catch((error) => {
      setError('This can happen if the code is malformed, expired, or has already been used.');
      console.log("error:", error)
    });
  }

  const verifyEmailAction = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const oobCode = urlParams.get('oobCode');
    const auth = urlParams.get('auth');

    // TODO: create a component for every case
    switch (mode) {
      case 'resetPassword':
        setActionCode(oobCode);
        setActionHandlerType("resetPassword");
        break;
       /*case 'recoverEmail':
        // Display email recovery handler and UI.
        handleRecoverEmail(auth, oobCode, lang);
        break; */
      case 'verifyEmail':
        setActionHandlerType("verifyEmail");
        handleVerifyEmail(oobCode);
        break;
      default:
        setError('Action not supported.');
        console.log("Error: invalid mode");
    }
  };

  const onGoToLoginPressed = async (event) => {
    event.preventDefault();
    
    history(`${process.env.PUBLIC_URL}/login`);
  };

  useEffect(() => {
    verifyEmailAction();
  }, []);

  return (
    <div className="login page-wrapper">
      <Container fluid={true} className="p-0">
        <div className="authentication-main m-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="d-flex h-100">
                    <div className="cont text-center b-light">
                      { actionHandlerType === 'verifyEmail' && isEmailVerified && !error &&
                        <VerifyEmailActionHandler />
                      }

                      { actionHandlerType === 'resetPassword' && !error &&
                        <ResetPasswordActionHandler actionCode={actionCode} />
                      }

                      { error &&
                        <Form className="w-100">
                          <div className="logo-wrapper">
                            <img src={require("../../../assets/images/logo/nationbuild-icon.png")} alt="" />
                          </div>
                          <div className="pb-4 pt-4">
                            <h4>An error occurred while processing this action</h4>
                          </div>
                          <hr />
                          <p className="text-justify pt-4 f-14" style={{ textAlign: "justify"}}> 
                            {error}
                          </p>

                          <div className="w-100">
                            <Button color="primary d-block w-100" onClick={onGoToLoginPressed}>
                              Go to Login
                            </Button>
                          </div>

                          <div className="w-100 pt-4">
                            Need help? <a href="mailto:hello@nationbuild.io">Send us an email</a>
                          </div>
                        </Form>
                      }
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default EmailActionHandler;
