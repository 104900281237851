import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, database } from './firebase';
const DatabaseContext = createContext(null);

export const useDatabase = () => {
  return useContext(DatabaseContext);
};
export const DatabaseProvider = ({ children }) => {
    const [currentUserData, setCurrentUserData] = useState(null);
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          const userRef = database.ref(`users/${user.uid}`);
          userRef.on('value', (snapshot) => {
            setCurrentUserData(snapshot.val());
          });
  
          return () => {
            userRef.off();
          };
        } else {
          setCurrentUserData(null);
        }
      });
  
      return () => {
        unsubscribe();
      };
    }, []);
  
    const value = {
      currentUserData,
      setCurrentUserData,
    };
  
    return (
      <DatabaseContext.Provider value={value}>{children}</DatabaseContext.Provider>
    );
  };

