import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    
    document.body.appendChild(script);
  }, []);
  
  return (
    <div
      name="termly-embed"
      data-id="89408b73-6183-40ee-a176-75f7f2269117"
      data-type="iframe"
    ></div>
  );
};

export default PrivacyPolicy;
