import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
    apiKey: "AIzaSyApfeQqfnkf9qoK39twVe1TFq5prC1ZP2E",
    authDomain: "ab-firebase-34709.firebaseapp.com",
    databaseURL: "https://ab-firebase-34709-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ab-firebase-34709",
    storageBucket: "ab-firebase-34709.appspot.com",
    messagingSenderId: "108138157618",
    appId: "1:108138157618:web:d72d0b2934c64924d20e2b",
    measurementId: "G-3L3YB40XV9"
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const perf = getPerformance(app);
