/* ECOMMERRCE APP */
export const GET_LIST = "GET_LIST";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";

//Wishlist
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";

//Filters
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SEARCH_BY = "SEARCH_BY";
export const SORT_BY = "SORT_BY";

/* TODO APP*/

export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";

/* CHAT APP*/
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const CHANGE_CHAT = "CHANGE_CHAT";
export const CREATE_CHAT = "CREATE_CHAT";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const REPLY_BY_SELECTED_USER = "REPLY_BY_SELECTED_USER";

// Email-app
export const GET_EMAILS = "GET_EMAILS";
export const GET_ALL_EMAILS = "GET_ALL_EMAILS";
export const GET_EMAIL_TYPES = "GET_EMAIL_TYPES";
export const UPDATE_EMAIL_TYPES = "UPDATE_EMAIL_TYPES";

//CUSTOMIZER
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";
export const ADD_TRANSPlAYOUT = "ADD_TRANSPlAYOUT";
export const ADD_SIDEBAR_BACKGROUND = "ADD_SIDEBAR_BACKGROUND";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";
