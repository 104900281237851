import React from "react";
import { Container, Row, Col, CardBody, Form } from "reactstrap";

const VerifyEmailAddress = () => {

  return (
    <div className="login page-wrapper">
      <Container fluid={true} className="p-0">
        <div className="authentication-main m-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="d-flex h-100">
                    <div className="cont text-center b-light">
                      <Form className="w-100">
                        <div className="logo-wrapper">
                          <a href="/">
                            <img src={require("../../../assets/images/logo/nationbuild-icon.png")}/>
                          </a>
                        </div>
                        <div className="pb-4 pt-4">
                          <h4>Verify you email address</h4>
                        </div>
                        <hr />
                        <p className="text-justify pt-4 f-14" style={{ textAlign: "justify"}}> 
                        Great news! You're almost done with setting up your NationBuild account!
                        We've sent a verification link to your inbox using the email address you provided during sign up.
                        <br /><br />
                        Check your inbox and click the link to confirm your account.
                        </p>
                        
                        <div>
                          Already verified your email address? <a href={`${process.env.PUBLIC_URL}/login`}>Log in</a>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default VerifyEmailAddress;
