import { db } from "src/component/alumnibuild/components/firebase";
import { collection, addDoc, getDocs, query, where, and, or, documentId, doc, updateDoc, getDoc } from "firebase/firestore";
import { DonorConnectionStatus } from "../entities/donor-connection-status";
import { getUserImage } from "./user-data-helpers";
import { sortByProperty } from "../utils/array-utils";
import { addRequestConnectionNotification, addAcceptedConnectionNotification } from "./notifications-data-helpers";
import { createChatMessage } from "./chat-data-helpers";

export async function createDonorConnection(data) {
  const reference = collection(db, 'donors_connections');

  await addDoc(reference, {
    'from_user_id': data.fromUserId,
    'to_user_id': data.toUserId,
    'status': DonorConnectionStatus.pending, 
    'created': new Date().toISOString()
  });

  return addRequestConnectionNotification(data.toUserId);
}

export async function declineDonorConnection(invitationId) {
  const reference = doc(db, "donors_connections", invitationId);

  return updateDoc(
    reference,
    { 'status': DonorConnectionStatus.declined , 'updated': new Date().toISOString() }
  );
}

export async function getUserInvitations(userId) {
  const reference = collection(db, "donors_connections");
  const snapshot = await getDocs(
    query(
      reference,
      or(
        where('from_user_id', "==", userId),
        where('to_user_id', "==", userId)
      )
    )
  );

  let result = {
    active: [],
    sent: [],
    received: []
  };

  if (snapshot.size !== 0) {
    const invitations = [];

    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];

      invitations.push({
        'id': doc.id,
        ...doc.data()
      });
    }
    
    result = {
      active: invitations.filter(i => (i.from_user_id === userId || i.to_user_id === userId) && i.status === DonorConnectionStatus.accepted),
      sent: invitations.filter(i => i.from_user_id === userId && i.status === DonorConnectionStatus.pending),
      received: invitations.filter(i => i.to_user_id === userId && i.status === DonorConnectionStatus.pending),
    }

    result.active = sortByProperty(result.active, "created"); // TODO: sort by database
    result.sent = sortByProperty(result.sent, "created"); // TODO: sort by database
    result.received = sortByProperty(result.received, "created"); // TODO: sort by database
  } 

  return result;
}

export async function getUsersWithConnections(invitations, currentUserId) {
  const usersIds = getIdsOfUsersFromInvitations(invitations, currentUserId);

  if (usersIds.length === 0) {
    return [];
  }

  const reference = collection(db, "users");
  const snapshot = await getDocs(
    query(
      reference,
      where(documentId(), 'in', [...new Set(usersIds)])
    )
  );
  const result = [];

  if (snapshot.size !== 0) {
    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const image = await getUserImage(doc.id);
      const data = doc.data();

      result.push({
        'id': doc.id,
        'image': image,
        'name': `${data.first_name} ${data.last_name}`,
        ...data
      });
    }
  } 

  return result;
}

export function getIdsOfUsersFromInvitations(invitations, currentUserId) {
  return [
    ...(invitations.active.map((i) => i.from_user_id === currentUserId ? i.to_user_id : i.from_user_id)),
    ...(invitations.sent.map((i) => i.to_user_id)),
    ...(invitations.received.map((i) => i.from_user_id))
  ];
}

export async function getIdsOfUsersWithConnections(currentUserId) {
  const invitations = await getUserInvitations(currentUserId);

  if (!invitations) {
    return [];
  }
 
  return getIdsOfUsersFromInvitations(invitations, currentUserId);
}

export async function getHasInvitationPendingFromUser(userId, currentUserLoggedId) {
  const reference = collection(db, "donors_connections");
  const snapshot = await getDocs(
    query(
      reference,
        where('status', "==", DonorConnectionStatus.pending),
        where('from_user_id', "==", userId),
        where('to_user_id', "==", currentUserLoggedId),
    )
  );

  return snapshot.size > 0;
}

export async function getIsAlreadySentInvitationToUser(userId, currentUserLoggedId) {
  const reference = collection(db, "donors_connections");
  const snapshot = await getDocs(
    query(
      reference,
        where('status', "==", DonorConnectionStatus.pending),
        where('from_user_id', "==", currentUserLoggedId),
        where('to_user_id', "==", userId),
    )
  );

  return snapshot.size > 0;
}

export async function getIsUserInMyNetwork(userId, currentUserLoggedId) {
  const reference = collection(db, "donors_connections");
  const snapshot = await getDocs(
    query(
      reference,
      and (
        where('status', "==", DonorConnectionStatus.accepted),
        or(
          and (where('from_user_id', "==", userId),
          where('to_user_id', "==", currentUserLoggedId)),
          or(
            and(where('to_user_id', "==", userId),
            where('from_user_id', "==", currentUserLoggedId)),
          )
        )
      )
    )
  );

  return snapshot.size > 0;
}

export async function acceptInvitationPendingFromUser(fields) {
  const reference = collection(db, "donors_connections");
  const snapshot = await getDocs(
    query(
      reference,
      where('from_user_id', "==", fields.from_user_id),
      where('to_user_id', "==", fields.to_user_id),
      where('status', "==", DonorConnectionStatus.pending)
    )
  );

  if (snapshot.size > 0) {
    await acceptDonorConnection(snapshot.docs[0].id);
    await addAcceptedConnectionNotification(fields.from_user_id);
    await createChatMessage({ "users": [ fields.from_user_id, fields.to_user_id ], "messages":[] });  
  }
}

export async function acceptDonorConnection(invitationId) {
  const reference = doc(db, "donors_connections", invitationId);

  return updateDoc(
    reference,
    { 'status': DonorConnectionStatus.accepted , 'updated': new Date().toISOString() }
  );
}