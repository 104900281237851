import { db } from "src/component/alumnibuild/components/firebase";
import { collection, query, getDocs } from "firebase/firestore";
import { sortByProperty } from "../utils/array-utils";

export async function getIndustriesData() {
  const reference = collection(db, "industries");
  const snapshot = await getDocs(
    query(reference)
  );
  
  if (snapshot.size !== 0) {
    const documents = [];

    snapshot.forEach(doc => {
      documents.push({ 'id': doc.id, ...doc.data() });
    });

    return sortByProperty(documents, "name");
  } else {
    return [];
  }
}
