import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AppLayout from "../App";

import { setThemes } from "../data/customizer/themes-helper";
import PrivateRoute from "./private-route";

import PublicSite from "../component/alumnibuild/public-site/home";
import Login from "../component/alumnibuild/components/login";
import VerifyEmail from "../component/alumnibuild/components/verify-email-address";
import ForgotPassword from "../component/alumnibuild/components/forgot-password";
import EmailActionHandler from "../component/alumnibuild/components/email-action-handler";
import SchoolDetails from "../component/alumnibuild/components/school-details";

import Error400 from "../pages/errors/error400";
import Error404 from "../pages/errors/error404";
import Error500 from "../pages/errors/error500";

import Maintenance from "../pages/maintenance";
import TermsAndConditions from "src/component/alumnibuild/components/terms-and-conditions";
import SignUp from "src/component/alumnibuild/components/signup";

import PrivacyPolicy from "src/component/alumnibuild/components/privacy-policy";
import { routes } from "./layouts-routes";

const MainRoutes = () => {
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {
    setThemes();
    const abortController = new AbortController();

    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Routes>
        <Route exact path={`${process.env.PUBLIC_URL}/home`} element={<PublicSite />} />
        <Route exact path="/" element={<PrivateRoute />}>
          {routes.map(({ path, Component }, i) => (
            <Route element={<AppLayout />} key={i}>
             {/*  {currentUser !== null || jwt_token 
              ? <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} /> 
              : ""
            } */}
              <Route path={path} element={Component} />
              <Route path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/home`} />} /> 
            </Route>
          ))}
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route path={`${process.env.PUBLIC_URL}/signup`} element={<SignUp />} />
        <Route path={`${process.env.PUBLIC_URL}/verify-email`} element={<VerifyEmail />} />
        <Route path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgotPassword />} />
        <Route path={`${process.env.PUBLIC_URL}/email-action-handler`} element={<EmailActionHandler />} />
        <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`} element={<TermsAndConditions />} />
        <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} element={<PrivacyPolicy />} />
        {/* <Route path={`${process.env.PUBLIC_URL}/s/:id`} element={<SchoolDetails />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/error-400`} element={<Error400 />}></Route>
        <Route path={`${process.env.PUBLIC_URL}/pages/error-404`} element={<Error404 />}></Route>
        <Route path={`${process.env.PUBLIC_URL}/pages/error-500`} element={<Error500 />}></Route>
      <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} element={<Maintenance />}></Route> */}
        <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/`} />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
