import React, { Fragment, useState, useEffect } from "react";
import { Search } from "react-feather";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
  Collapse,
  Label,
  FormGroup
} from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { getIndustriesData } from "../../data-helpers/industries-data-helpers";
import { getCountriesData } from "../../data-helpers/countries-data-helpers";
import { getHobbiesData } from "../../data-helpers/hobbies-data-helpers";
import { getRegionsData } from "../../data-helpers/regions-data-helpers";
import { getLookingToData } from "../../data-helpers/looking-to-data-helpers";
import { getResearchThemesData } from "../../data-helpers/research-themes-data-helpers";

const UsersFilter = (props) => {
  const [filters, setFilters] = useState({});
  const [isGeneralFilterOpen, setIsGeneralFilterOpen] = useState(true);
  const [isInterestsFilterOpen, setIsInterestsFilterOpen] = useState(true);

  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [lookingToOptions, setLookingToOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [researchThemes, setResearchThemes] = useState([]);

  const [countrySelected, setCountrySelected] = useState([]);
  const [industrySelected, setIndustrySelected] = useState([]);
  const [countryOfOriginSelected, setCountryOfOriginSelected] = useState([]);
  const [regionsOfInterestSelected, setRegionsOfInterestSelected] = useState([]);
  const [industriesOfInterestSelected, setIndustriesOfInterestSelected] = useState([]);
  const [hobbiesOfInterestSelected, setHobbiesOfInterestSelected] = useState([]);
  const [lookingToOptionsSelected, setLookingToOptionsSelected] = useState([]);
  const [researchThemesOfInterestSelected, setResearchThemesOfInterestSelected] = useState([]);

  const onFilterChanged = (fieldName, fieldValue) => {
    let newValues = { ...filters, [fieldName]:fieldValue };

    const isEmptyValue = fieldValue === undefined || (Array.isArray(fieldValue) && fieldValue.length === 0);

    if (isEmptyValue) {
      delete newValues[fieldName];
    }
    
    setFilters(newValues);

    props.onFilterChanged(newValues);
  };

const onCountryOfOriginChanged = (selections) => {
    const countrySelected = selections.length === 1 ? selections[0].name : undefined;

    onFilterChanged('country_of_origin', countrySelected);
    setCountryOfOriginSelected(selections);
  };

  const onCountryChanged = (selections) => {
    const countrySelected = selections.length === 1 ? selections[0].name : undefined;

    onFilterChanged('country', countrySelected);
    setCountrySelected(selections);
  };

  const onIndustryChanged = (selections) => {
    const selected = selections.length === 1 ? selections[0].name : undefined;

    onFilterChanged('industry', selected);
    setIndustrySelected(selections);
  };

  const onRegionsOfInterestChanged = (selections) => {
    onFilterChanged('regions_of_interest', selections.map((c)=> c.name));
    setRegionsOfInterestSelected(selections);
  };

  const onIndustriesOfInterestChanged = (selections) => {
    onFilterChanged('industries_of_interest', selections.map((c)=> c.name));
    setIndustriesOfInterestSelected(selections);
  };

  const onHobbiesOfInterestChanged = (selections) => {
    onFilterChanged('hobbies_of_interest', selections.map((c)=> c.name));
    setHobbiesOfInterestSelected(selections);
  };

  const onLookingToOptionsChanged = (selections) => {
    onFilterChanged('looking_to', selections.map((c)=> c.name));
    setLookingToOptionsSelected(selections);
  };

  const onResearchThemesChanged = (selections) => {
    onFilterChanged('research_themes_of_interest', selections.map((c)=> c.name));
    setResearchThemesOfInterestSelected(selections);
  };

  const getCountries = async (level) => {
    const countriesData = await getCountriesData(level);

    setCountries(()=>[ ...countriesData ]);
  };

  const getIndustries = async () => {
    const data = await getIndustriesData();

    setIndustries(()=>[ ...data ]);
  };

  const getHobbies = async () => {
    const data = await getHobbiesData();

    setHobbies(()=>[ ...data ]);
  };

  const getRegions = async () => {
    const data = await getRegionsData();

    setRegions(()=>[ ...data ]);
  };

  const getLookingToOptions = async () => {
    const data = await getLookingToData();

    setLookingToOptions(()=>[ ...data ]);
  };

  const getResearchThemes = async () => {
    const data = await getResearchThemesData();

    setResearchThemes(()=>[ ...data ]);
  };


  useEffect(() => {
    getCountries();
    getIndustries();
    getHobbies();
    getRegions();
    getLookingToOptions();
    getResearchThemes();
  }, []);

  return (
    <Row>
      <Col xl="12">
        <Card className="overflow-visible">
          <CardHeader>
            <h5 className="mb-0">
              <Button
                color="link ps-0"
                onClick={() => setIsGeneralFilterOpen(!isGeneralFilterOpen)}
                aria-expanded={isGeneralFilterOpen}
                aria-controls="collapseicon1"
              >
                General filters
              </Button>
            </h5>
          </CardHeader>
          <Collapse isOpen={isGeneralFilterOpen}>
            <CardBody className="">
              <div className="checkbox-animated pb-4">
              <FormGroup>
                <Label className="d-block form-label" htmlFor="chk-ani">
                Country of Origin  
                  <Typeahead
                    id="country_of_origin"
                    labelKey="name"
                    options={countries}
                    selected={countryOfOriginSelected}
                    placeholder="Select a country"
                    onChange={onCountryOfOriginChanged}
                  /> 
                </Label>
                </FormGroup>
                <Label className="d-block form-label" htmlFor="chk-ani">
                Country of residence  
                  <Typeahead
                    id="country_selection"
                    labelKey="name"
                    options={countries}
                    selected={countrySelected}
                    placeholder="Select a country"
                    onChange={onCountryChanged}
                  /> 
                </Label>
                <Label className="d-block form-label">
                Industry
                  <Typeahead
                    id="industry_selection"
                    labelKey="name"
                    options={industries}
                    selected={industrySelected}
                    placeholder="Select an industry"
                    onChange={onIndustryChanged}
                  /> 
                </Label>
                
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </Col>

      <Col xl="12">
        <Card className="overflow-visible">
          <CardHeader>
            <h5 className="mb-0">
              <Button
                color="link ps-0"
                onClick={() => setIsInterestsFilterOpen(!isInterestsFilterOpen)}
                aria-expanded={isInterestsFilterOpen}
                aria-controls="collapseicon1"
              >
                Interest filters
              </Button>
            </h5>
          </CardHeader>
          <Collapse isOpen={isInterestsFilterOpen}>
            <CardBody className="">
              <Row className="pb-4">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="form-label">Looking to</Label>
                    <Typeahead
                      id="looking_to"
                      labelKey="name"
                      options={lookingToOptions}
                      selected={lookingToOptionsSelected}
                      multiple
                      placeholder="Select the options"
                      onChange={onLookingToOptionsChanged}
                    />   
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="form-label">Regions</Label>
                    <Typeahead
                      id="regions_of_interest"
                      labelKey="name"
                      options={regions}
                      selected={regionsOfInterestSelected}
                      multiple
                      placeholder="Select regions"
                      onChange={onRegionsOfInterestChanged}
                    />   
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="form-label">Industries</Label>
                    <Typeahead
                     id="industries_of_interest"
                     labelKey="name"
                     options={industries}
                     selected={industriesOfInterestSelected}
                     multiple
                     placeholder="Select industries"
                     onChange={onIndustriesOfInterestChanged}
                    />   
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="form-label">Research Themes</Label>
                    <Typeahead
                    id="research_themes_of_interest"
                    labelKey="name"
                    options={researchThemes}
                    selected={researchThemesOfInterestSelected}
                    multiple
                    placeholder="Select research options"
                    onChange={onResearchThemesChanged}
                    />   
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="form-label">Hobbies</Label>
                    <Typeahead
                    id="hobbies_of_interest"
                    labelKey="name"
                    options={hobbies}
                    selected={hobbiesOfInterestSelected}
                    multiple
                    placeholder="Select hobbies"
                    onChange={onHobbiesOfInterestChanged}
                    />   
                  </FormGroup>
                </div>
              </Row>  
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
};

export default UsersFilter;
