import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Chart from "react-apexcharts";
import { Container, Row, Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { apexchartsareaspaline, apexecommerce2, apexecommerce3 } from "../../general/dashboard/charts/apexchartsData";
import { getSchoolAnalytics } from '../data-helpers/analytics-data-helpers';
import { useNavigate } from "react-router-dom";
import { getSchoolLastCampaign } from "../data-helpers/campaign-data-helpers";

const SchoolDashboard = (props) => {
  const history = useNavigate();
  const [userName, setUserName] = useState(localStorage.getItem("username"));
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    localStorage.setItem("isUser", null);
  };
  const [schoolId, setSchoolId] = useState(localStorage.getItem("userSchoolId"));
  const [analytics, setAnalytics ] = useState({});
  const [weeklyAnalytics, setWeeklyAnalytics] = useState({});
  const [isComponentReady, setIsComponentReady] = useState(false);
  const [hasActiveCampaign, setHasActiveCampaign] = useState(false);

  const getData = async () => {
    const campaign = await getSchoolLastCampaign(schoolId);
    const isActiveCampaign = campaign ? campaign.isActive : false;

    setHasActiveCampaign(isActiveCampaign);

    //TODO: These metrics or information needs to be previously calculated
    const data = await getSchoolAnalytics(schoolId);
    
    setAnalytics({ ...data });

    let weeklyAnalyticsData = apexchartsareaspaline;

    weeklyAnalyticsData.series = [
      {
        name: "",
        'data': data.weeklyBasis.total
      }
    ];

    weeklyAnalyticsData.options.xaxis.categories = data.weeklyBasis.weeks;

    setWeeklyAnalytics({ ...weeklyAnalyticsData });

    setIsComponentReady(true);
  }

  const onCreateCampaignClicked = () => {
    history(`${process.env.PUBLIC_URL}/campaigns/new`);
  }

  const onViewCampaignClicked = () => {
    history(`${process.env.PUBLIC_URL}/school/details/${schoolId}`);
  }

  useEffect(() => {
    getData();
    if (localStorage.getItem("isUser") === "true") {
      setModal(true);
    } else {
      setModal(false);
    }
  }, []);
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="" />
      <Container fluid={true}>
        <Row>
          <Col lg="12 xl-100">
            <Row>
              <Col xl="9 xl-50" md="9 box-col-9">
                <Card className="o-hidden">
                  <CardBody className="tag-card">
                    <div className="default-chart f-30">
                     Hello, {userName}
                    </div>
                    <div className="default-chart text-muted">
                     Here some reports on your dashboard
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3 xl-50" md="3 box-col-3">
                <Card className="gradient-primary o-hidden">
                  <CardBody className="tag-card text-center">
               
                    {isComponentReady &&
                    <div className="default-chart" style={{ minHeight: '66px' }}>
                      { hasActiveCampaign 
                      ? <button onClick={onViewCampaignClicked} className="btn btn-light btn-lg">View Campaign</button>
                      : <button onClick={onCreateCampaignClicked} className="btn btn-light btn-lg">Create Campaign</button>
                      }
                    </div>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="12 xl-100">
            <Row className="ecommerce-chart-card">
              <Col xl="6 xl-50" md="6 box-col-6">
                <Card className="gradient-primary o-hidden">
                  <CardBody className="tag-card">
                    <div className="ecommerce-chart">
                      <div className="media ecommerce-small-chart">
                        <div className="small-bar">
                          <Chart options={apexecommerce2.options} series={apexecommerce2.series} height="80" type="bar" width={120} />
                        </div>
                        <div className="sale-chart">
                          <div className="media-body m-l-40">
                            <h5 className="f-w-100 f-w-700 m-l-10">Total raised</h5>
                            <h6 className="f-w-100 m-l-10 f-36">${analytics.year || 0}</h6>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                    <span className="tag-hover-effect">
                      <span className="dots-group">
                        <span className="dots dots1"></span>
                        <span className="dots dots2 dot-small"></span>
                        <span className="dots dots3 dot-small"></span>
                        <span className="dots dots4 dot-medium"></span>
                        <span className="dots dots5 dot-small"></span>
                        <span className="dots dots6 dot-small"></span>
                        <span className="dots dots7 dot-small-semi"></span>
                        <span className="dots dots8 dot-small-semi"></span>
                        <span className="dots dots9 dot-small"> </span>
                      </span>
                    </span>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6 xl-50" md="6 box-col-6">
                <Card className="gradient-secondary o-hidden">
                  <CardBody className="tag-card">
                    <div className="ecommerce-chart">
                      <div className="media ecommerce-small-chart">
                        <div className="small-bar">
                          <Chart options={apexecommerce3.options} series={apexecommerce3.series} height="80" type="bar" width={120} />
                        </div>
                        <div className="sale-chart">
                          <div className="media-body m-l-40">
                            <h5 className="f-w-100 f-w-700 m-l-10">Donor count</h5>
                            <h6 className="f-w-100 m-l-10 f-36">{analytics.donorCount || 0}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="tag-hover-effect">
                      <span className="dots-group">
                        <span className="dots dots1"></span>
                        <span className="dots dots2 dot-small"></span>
                        <span className="dots dots3 dot-small"></span>
                        <span className="dots dots4 dot-medium"></span>
                        <span className="dots dots5 dot-small"></span>
                        <span className="dots dots6 dot-small"></span>
                        <span className="dots dots7 dot-small-semi"></span>
                        <span className="dots dots8 dot-small-semi"></span>
                        <span className="dots dots9 dot-small"> </span>
                      </span>
                    </span>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Col>

          <Col xl="12 xl-100 box-col-12">
            <Card className="crypto-revenue">
              <CardHeader className="pb-0 d-flex">
                <h5>
                  Weekly Statistics
                  <span className="badge rounded-pill pill-badge-secondary f-14 f-w-600">{ analytics.currentYear }</span>
                </h5>
                <ul className="creative-dots">
                  <li className="bg-primary big-dot"></li>
                  <li className="bg-secondary semi-big-dot"></li>
                  <li className="bg-warning medium-dot"></li>
                  <li className="bg-info semi-medium-dot"></li>
                  <li className="bg-secondary semi-small-dot"></li>
                  <li className="bg-primary small-dot"></li>
                </ul>
                <div className="header-right pull-right text-end">
                  <h6 className="f-w-700 mb-0">Total {analytics.year} $</h6>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                { isComponentReady && <Chart options={apexchartsareaspaline.options} series={apexchartsareaspaline.series} height="300" type="area" /> }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} className="welcome-popup modal-dialog-centered">
          <button onClick={toggle} className="btn-close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <ModalBody>
            <ModalHeader></ModalHeader>
            <div className="contain p-30">
              <div className="text-center">
                <h3>Welcome to Poco admin</h3>
                <p>start your project with developer friendly admin </p>
                <button onClick={toggle} className="btn btn-primary btn-lg txt-white" type="button" data-bs-dismiss="modal" aria-label="Close">
                  Get Started
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default SchoolDashboard;
