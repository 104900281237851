import React, { Fragment, useState } from "react";
import { Search } from "react-feather";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
  Collapse,
  Label
} from "reactstrap";
import UsersFilters from "./users-filters";

const ExplorerFilter = (props) => {
  const [isFilter, setIsFilter] = useState(true);
  const [filters, setFilters] = useState({  });
  const [searchFilter, setSearchFilter] = useState("");

  const onSearchInputChanged = (event) => {
    setSearchFilter(event.target.value);

    props.onFilterChanged(joinFilters(filters, event.target.value));
  }
  
  const onFilterChanged = (filters) => {
    setFilters(current => {
      return { ...current, ...filters };
    });

    props.onFilterChanged(joinFilters(filters, searchFilter));
  };

  const joinFilters = (filters, searchFilter) => {
    const isEmptySearchValue = searchFilter.length === 0;

    if (isEmptySearchValue) {
      return filters;
    } else {
      return { 'searchInputText': searchFilter, ...filters };
    }
  }

  return (
    <Fragment>
      <Col md="3" xl="3">
        <div
          className="default-according style-1 faq-accordion job-accordion"
          id="accordionoc"
        >
        {/*   <Row>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <h5 className="mb-0">
                    <Button
                      color="link ps-0"
                      onClick={() => setIsFilter(!isFilter)}
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      Filter
                    </Button>
                  </h5>
                </CardHeader>
                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <div className="job-filter">
                      <div className="faq-form">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Search.."
                          value={searchFilter}
                          onChange={onSearchInputChanged}
                        />
                        <Search className="search-icon" />
                      </div>
                    </div>
                    <div className="platformOptions-checkbox">
                    {props.platformOptions.map((platformOptions, i) => {
                      return (
                        <Label className="d-block form-label" key={i}>
                           <Input
                            className="checkbox_animated"
                            type="checkbox"
                            onChange={() => onPlatformOptionsChanged(platformOptions.id)}
                            checked={ true }
                          />
                          { platformOptions.name }
                        </Label>
                        );
                      })}
                    </div>
                   
                    <Button
                      color="primary"
                      className="text-center mt-4"
                    >
                      Find
                    </Button>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          
          </Row> */}

          <UsersFilters onFilterChanged={onFilterChanged} />
        </div>
      </Col>
    </Fragment>
  );
};

export default ExplorerFilter;
