import React, { useState } from "react";
import { Alert, Container, Row, Col, CardBody, Form, FormGroup, Input, Button, Label,  } from "reactstrap";
import { Link } from "react-router-dom";
import { auth } from "./firebase";

const VerifyEmailAddress = () => {
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const onResetPressed = async (event) => {
    event.preventDefault();

    if (email === "" || processing) {
      return;
    }

    setProcessing(true);

    try {
      await auth.sendPasswordResetEmail(email);
      setEmailSent(true);
    } catch (error) {
      setError('Error resetting password: ' + error.message);
    }

    setProcessing(false);
  }

  return (
    <div className="login page-wrapper">
      <Container fluid={true} className="p-0">
        <div className="authentication-main m-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="d-flex h-100">
                    <div className="cont text-center b-light">
                      <Form className="w-100">
                        <div className="logo-wrapper">
                          <a href="/">
                            <img src={require("../../../assets/images/logo/nationbuild-icon.png")}/>
                          </a>
                        </div>
                        { !emailSent ? 
                         <div>
                           <div className="pb-4 pt-4">
                              <h4>Forgot password?</h4>
                            </div>
                            <hr />
                            <p className="text-justify pt-4 f-14" style={{ textAlign: "justify"}}> 
                              No worries, we will send you password recover instructions.
                            </p>
                            <FormGroup>
                              <Input className="form-control" type="email" placeholder="Enter your email address" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormGroup>

                            <FormGroup className="d-flex flex-wrap mt-3 mb-0">
                              <Button color="primary d-block w-100" onClick={onResetPressed}>
                                {processing ? 'Sending email...' : 'Reset password' }
                              </Button>
                            </FormGroup>
                         </div>
                         :
                         <div>
                            <div className="pb-4 pt-4">
                              <h4>Check your mail</h4>
                            </div>
                            <hr />
                            <p className="text-justify pt-4 f-14" style={{ textAlign: "justify"}}> 
                              We have sent a password recover instructions to your email address.
                            </p>
                          </div>
                        }

                        { error && 
                          <Alert color="danger">
                            <p>{ error }</p>
                          </Alert>
                        }
                        <div>
                           <Link to={`${process.env.PUBLIC_URL}/login`}>Back to Log in</Link>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default VerifyEmailAddress;
