import React, { Fragment, useState, useEffect } from "react";
import { getCurrentAuthUser } from "../data-helpers/user-data-helpers";
import AlumniDashboard from "./alumni-dashboard";
import SchoolDashboard from "./school-dashboard";

const Dashboard = (props) => {
  const [user, setUser] = useState({});
  const [isComponentReady, setIsComponentReady] = useState(false);

  const getUser = async () => {
    const user = await getCurrentAuthUser();

    setUser(user);
    setIsComponentReady(true);
  }

  useEffect(() => {
    getUser();
  }, []);
  
  return (
   <div>
      { isComponentReady && user.role === 'alumni' && <AlumniDashboard /> }
      { isComponentReady && (user.role === 'school-office' || user.role === 'school-page-administrator') && <SchoolDashboard /> }
   </div>
  );
  
};

export default Dashboard;
