
import configDB from './config';

export function setThemes() {
  const layout = configDB.data.color.layout_version;
  const color = configDB.data.color.color;

  document.body.classList.add(layout);
  document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);
}
  
  