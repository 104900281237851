export function hasUrlProtocol(url) {
  return url.startsWith("https://") || url.startsWith("http://");
}

export function isValidUrl(url) {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator

  return !!urlPattern.test(url);
}

export function isBase64ImageValid(base64String) {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function() {
      resolve(true);
    };

    img.onerror = function() {
      resolve(false);
    };

    img.src = base64String;
  });
}