import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col,Button, ButtonGroup, Container, CardBody, Form } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import SignUpAlumni from "./signup-alumni";
import SignUpSchool from "./signup-school";

const SignUp = () => {
  const [userType, setUserType] = useState("alumni");

  const onUserTypeChanged = (type) => {
    setUserType(type);
  }

  return (

    <div className="signup page-wrapper">
    <Container fluid={true} className="p-0">
      <div className="authentication-main m-0">
        <Row>
          <Col md="12">
            <div className="auth-innerright">
              <div className="authentication-box h-100">
                <CardBody className="d-flex">
                  <div className="cont text-center b-light">
                    <Form className="w-100">
                      <div className="logo-wrapper mt-4">
                          <a href="/">
                            <img src={require("../../../assets/images/logo/nationbuild-icon.png")}/>
                          </a>
                      </div>
                      <div className="pt-4 pb-2">
                        <h4 className="text-center">Sign up</h4>
                      </div>

                     {/*  <ButtonGroup className="w-100">
                          <Button 
                            color="light"
                            onClick={(event) => onUserTypeChanged('alumni')}
                            style={ { width: '50%' }}
                            className={ userType === 'alumni' ? "txt-dark active" : "" }
                          >
                            I'm a Donor
                          </Button>
                          <Button
                            color="light"
                            onClick={(event) => onUserTypeChanged('school')}
                            className={ userType === 'school' ? "txt-dark active" : "" }
                          >
                            I'm an Administrator
                          </Button>
                        </ButtonGroup> */}
                       
                        {userType === 'alumni' ? <SignUpAlumni />: <SignUpSchool/> }
                        <div className="text-center">
                          or
                        </div>
                        <div className="mt-3">
                          Already have an account? <Link to={`${process.env.PUBLIC_URL}/login`}>Log in</Link>
                        </div>
                        <div className="text-muted mt-6 mb-1">
                          By signing up with Nationbuild, you agree to our <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`} target="_blank">Terms and Conditions</Link>.
                        </div>
                      </Form>
                  </div>
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  </div>

    
  );
};

export default SignUp;
