import { db } from "src/component/alumnibuild/components/firebase";
import { collection, query, getDocs, where, doc, updateDoc, addDoc, limit, documentId, orderBy, Timestamp } from "firebase/firestore";
import { sortByProperty } from "../utils/array-utils";
import { getTimeDifferenceFromNow } from "../utils/date-utils";
import { getUserImage } from "./user-data-helpers";

export async function createNotification(fields) {
  const reference = collection(db, 'notifications');
  
  return addDoc(reference, {
    ...fields,
    'is_unread': true,
    'created_timestamp': Timestamp.fromDate(new Date()),
    'created': new Date().toISOString()
  });
}

export async function readCurrentNotifications(contactedUserId) {
  const currentUserLoggedId = localStorage.getItem("userId");
  const notification = await getUserNotificationByChatMessage(contactedUserId, currentUserLoggedId)
  
  if (notification) {
      await removeNotification(notification.id);
  }
}

export async function getUserNotificationByChatMessage(currentUserId, toUserId) {
  const reference = collection(db, "notifications");
  const snapshot = await getDocs(
    query(
      reference,
      where('to_user_id', "==", toUserId),
      where('from_user_id', "==", currentUserId),
      where('is_unread', "==", true),
      where('type', "==", "message")
    )
  );

  if (snapshot.size > 0) {
    const id = snapshot.docs[0].id;

    return {
      'id': id,
      ...snapshot.docs[0].data(),
    };
  } else {
    return undefined;
  }
}

export async function getUserNotifications(currentUserId) {
  //TODO: paginate
  const reference = collection(db, "notifications");
  const snapshot = await getDocs(
    query(
      reference,
      where('to_user_id', "==", currentUserId),
      limit(100)
    )
  );

  let result = [];
  
  if (snapshot.size > 0) {
    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const data = doc.data();
      const readableDate = getTimeDifferenceFromNow(data.created);
      const fromUserImage = await getUserImage(data.from_user_id);

      result.push({
         ...data,
         'id': doc.id,
         'from_user_image': fromUserImage,
         'readable_date': readableDate
      });
    }

    const isStringDateProperty = true;

    result = sortByProperty(result, "created", isStringDateProperty);
  }  
  
  return result; 
}


export async function getUserNotificationsCount(currentUserId) {
  const reference = collection(db, "notifications");
  const snapshot = await getDocs(
    query(
      reference,
      where('to_user_id', "==", currentUserId),
      where('is_unread', "==", true)
    )
  );

  return snapshot.size; 
}

export async function removeNotification(notificationId) {
  const reference = doc(db, "notifications", notificationId);
  const fields = { 
    'is_unread': false,
    'updated': new Date().toISOString()
  };

  return updateDoc(reference, fields);
}

export async function addRequestConnectionNotification(toUserId) {
  const userName = localStorage.getItem("username");
  const currentUserLoggedId = localStorage.getItem("userId");
  const content = `${userName} would like to connect with you`;

  return createNotification({
    'to_user_id': toUserId,
    'from_user_id': currentUserLoggedId,
    'content': content,
    'route': 'user/profile/' + currentUserLoggedId,
    'type': 'connection',
    'isMessageEnabled': false
  });
}

export async function addAcceptedConnectionNotification(toUserId) {
  const userName = localStorage.getItem("username");
  const currentUserLoggedId = localStorage.getItem("userId");
  const content = `${userName} is now a connection`;

  return createNotification({
    'to_user_id': toUserId,
    'from_user_id': currentUserLoggedId,
    'content': content,
    'route': 'my-connections',
    'type': 'connection',
    'isMessageEnabled': true
  });
}

export async function addSendMessageNotification(toUserId) {
  let sendNotification = false;
  
  const currentUserLoggedId = localStorage.getItem("userId");
  const lastNotificationByChatMessage = await getUserLastNotificationByChatMessage(currentUserLoggedId, toUserId);
  const lastNotificationOfUserWasRead = lastNotificationByChatMessage && !lastNotificationByChatMessage.is_unread;

  if (lastNotificationByChatMessage === undefined) {
    sendNotification = true;
  } else if (lastNotificationOfUserWasRead) {
    const lastMessageTime = new Date(lastNotificationByChatMessage.created);
    const currentTime = new Date();
    const timeDifferenceInHours = (currentTime - lastMessageTime) / (1000 * 60 * 60);
    
    if (timeDifferenceInHours > 1) {
      sendNotification = true;
    }
  }


  if (sendNotification) {
    const userName = localStorage.getItem("username");
    const content = `${userName} sent you a message`;

    return createNotification({
      'to_user_id': toUserId,
      'from_user_id': currentUserLoggedId,
      'content': content,
      'route': 'my-connections',
      'type': 'message',
      'isMessageEnabled': true
    });
  }
}

export async function getUserLastNotificationByChatMessage(currentUserId, toUserId) {
  const reference = collection(db, "notifications");
  const snapshot = await getDocs(
    query(
      reference,
      orderBy("created_timestamp", "desc"),
      where('to_user_id', "==", toUserId),
      where('from_user_id', "==", currentUserId),
      where('type', "==", "message")
    )
  );

  if (snapshot.size > 0) {
    const id = snapshot.docs[0].id;

    return {
      'id': id,
      ...snapshot.docs[0].data(),
    };
  } else {
    return undefined;
  }
}
