import React, { useState, useEffect } from "react";
import { Alert, Row, Col, FormGroup, Input, Button } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { createUser } from "../data-helpers/user-data-helpers";
import { getCountriesData } from "../data-helpers/countries-data-helpers";

const SignUpAlumni = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({ 'country': '', 'first_name': '', 'last_name': '', 'role': 'alumni' });
  const [error, setError] = useState(undefined);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countries, setCountries] = useState([]);

  const history = useNavigate();

  const onFirstNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, first_name: event.target.value }));
    setError(undefined);
  };

  const onLastNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, last_name: event.target.value }));
    setError(undefined);
  };

  const onEmailChanged = (event) => {
    setEmail(event.target.value);
    setUser((prevState) => ({ ...prevState, email: event.target.value }));
    setError(undefined);
  };

  const onPasswordChanged = (event) => {
    setPassword(event.target.value);
    setError(undefined);
  };

  const onCountryChanged = (values) => {
    const countrySelected = values.length === 1 ? values[0].name : undefined;

    setUser((prevState) => ({ ...prevState, country: countrySelected }));
    setError(undefined);
  };

  const onCountryBlur = (event) => {
    const currentValue = event.target.value.trim();
    const foundCountry = countries.find((country) => country.name === currentValue);

    setUser((prevState) => ({ ...prevState, country: foundCountry ? foundCountry.name: undefined }));
    setError(undefined);
  }

  const getCountries = async () => {
    const data = await getCountriesData();

    setCountries(()=>[ ...data ]);
  };

  const createUserWithEmailAndPassword = async () => {
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(email, password);
      await createUser(user);

      userCredential.user.sendEmailVerification();

      auth.signOut();

      history(`${process.env.PUBLIC_URL}/verify-email`);

      return true
    } catch (error) {
      console.log("createUserWithEmailAndPassword error:", error);
      setError(error.code === 'auth/weak-password' ? 'The password is too weak.' : error.message.replace('Firebase:', ''));
      setLoading(false);
      return false;
    }
  };

  const onSignUp = async (event) => {
    event.preventDefault();

    if (!user.country) {
      setError("Please select a valid country.");
      return;
    }

    if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
      setError("Please enter a valid email.");
      return;
    }

    if (user.first_name === ""
        || user.last_name === ""
        || email === ""
        || password === ""
      ) {
      setError("Please complete all the information.");
      return;
    }

    setLoading(true);

    await createUserWithEmailAndPassword();
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className="w-100 p-0 h-100">
      <Row className="text-left mt-4">
        <Col md="12">
          <FormGroup>
            <Typeahead
              id="country_selection"
              labelKey="name"
              options={countries}
              placeholder="Select your country of residence"
              onChange={onCountryChanged}
              onBlur={onCountryBlur}
            />                               
          </FormGroup>
        </Col>
        <Col md="12 text-left">
          <FormGroup>
            <Input type="text" placeholder="First Name" onChange={onFirstNameChanged}/>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Input type="text" placeholder="Last Name" onChange={onLastNameChanged}/>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Input type="email" placeholder="Email address" onChange={onEmailChanged}/>
      </FormGroup>
      <FormGroup>
        <Input type="password" placeholder="Password" onChange={onPasswordChanged} />
      </FormGroup>
      { error && 
        <Alert color="danger">
          <p>{ error }</p>
        </Alert>
      }
      <Row className="dflex">
        <Col sm="12">
          <FormGroup className="d-flex flex-wrap mt-3 mb-0">
            {loading ? (
              <Button color="primary d-block w-100" disabled={loading}>
                Loading...
              </Button>
            ) : (
              <Button color="primary d-block w-100" onClick={onSignUp}>
                Sign Up
              </Button>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default SignUpAlumni;
