
export function getRemainingDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}


const durationMonthOptions = [
  {
    'option': 'one-month',
    'value': 1
  },
  {
    'option': 'two-months',
    'value': 2
  },
  {
    'option': 'three-months',
    'value': 3
  }
];


export function getDurationFromMonths(optionSelected) {
  const option = durationMonthOptions.find(d => d.option === optionSelected) || 1;
  const currentDate = new Date();
  
  let duration = new Date();

  duration.setMonth( currentDate.getMonth() + option.value);

  return duration.toISOString();
}

export function getReadableDateFromString(ISOdateString) {
  const date = new Date(ISOdateString);

  return date.toDateString();
}

export function setDateTimeFormat(dateString) {
  const date = new Date(dateString);

  return date.toDateString();
}

export function getTimeDifferenceFromNow(dateString) {
  const postDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifference = currentDate - postDate;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const weeksDifference = Math.floor(daysDifference / 7);
  const monthsDifference = Math.floor(daysDifference / 30);
  const yearsDifference = Math.floor(daysDifference / 365);

  if (yearsDifference > 0) {
    return yearsDifference === 1 ? '1 year ago' : `${yearsDifference} years ago`;
  } else if (monthsDifference > 0) {
    return monthsDifference === 1 ? '1 month ago' : `${monthsDifference} months ago`;
  } else if (weeksDifference > 0) {
    return weeksDifference === 1 ? '1 week ago' : `${weeksDifference} weeks ago`;
  } else if (daysDifference > 0) {
    return daysDifference === 1 ? '1 day ago' : `${daysDifference} days ago`;
  } else if (hoursDifference > 0) {
    return hoursDifference === 1 ? '1 hour ago' : `${hoursDifference} hours ago`;
  } else if (minutesDifference > 0) {
    return minutesDifference === 1 ? '1 minute ago' : `${minutesDifference} minutes ago`;
  } else {
    return 'Just now';
  }
}

