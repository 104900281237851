import React, { useState, useEffect } from "react";
import { Row, Card, Progress, Button, Modal, ModalHeader, ModalBody, Label , Input, Col } from "reactstrap";
import { getRemainingDays, getDurationFromMonths } from "../utils/date-utils";
import { useNavigate } from "react-router-dom";

const CampaignDetailsWidget = (props) => {
  const [shareLink, setShareLink] = useState(`${window.location.host}/s/${props.campaignData.school_id}`);
  const [campaignData, setCampaignData] = useState({...props.campaignData});
  const [remainingDays, setRemainingDays] = useState(0);
  const [raisedProgressBar, setRaisedProgressBar] = useState(0);
  const isPreviewModeEnable = props.isPreviewModeEnable || false;
  const history = useNavigate();
  const [modal, setModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setCopied(false);
  };

  const getCampaignRemainingDays = () => {
    if (props.isPreviewModeEnable) {
      const startDate = new Date().toISOString();
      const endDate = getDurationFromMonths(campaignData.duration);

      setRemainingDays(getRemainingDays(startDate, endDate));
    } else {
      const startCampaignDate = new Date(campaignData.start_date);
      const endCampaignDate = new Date(campaignData.end_date);

      setRemainingDays(getRemainingDays(startCampaignDate, endCampaignDate));
    }
  }

  const onDonateButtonClicked = () => {
    if (isPreviewModeEnable) {
      return;
    }

    history(`${process.env.PUBLIC_URL}/campaign/donate/${campaignData.school_id}`);
  }

  const onCopyClicked = () => {
    navigator.clipboard.writeText(shareLink);

    setCopied(true);
  }

  const getRaisedProgress = () => {
    const goal = campaignData.goal * 1;
    const isGoalReached = campaignData.totalRaised >= goal;

    return isGoalReached ? 100 : (campaignData.totalRaised / campaignData.goal * 1) * 100;
  }

  useEffect(() => {
    getCampaignRemainingDays();
    setRaisedProgressBar(getRaisedProgress());
  }, []);
  return (
    <div className="campaign-details">
      { isPreviewModeEnable &&
        <Row>
          <div className="col-md-12 mt-4 text-center">
            <h4>Campaign preview mode</h4>
          </div>
        </Row>
      }
      <Row>
        <div className="col-md-12 mt-4">
          <span className="text-muted f-20 text f-w-700"> Active Campaign </span>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mt-1">
          <span className="f-26 text f-w-700">{campaignData.title}</span>
        </div>
      </Row>
      <Row className="mt-2">
        <div className="col-sm-12 col-md-8 h-100">
          <Row>
            <img
              src={campaignData.image}
              style={{
                objectFit: 'contain',
                width: "100%",
                height: "100%",
              }}
            />
          </Row>
          <Row>
          <span className="pt-2 f-16 text-justify f-w-500">{campaignData.description}</span>
          </Row>
        </div>
        <div className="col-sm-12 col-md-4">
          <Card
            className="h-100 b-r-10 p-4"
            style={{
              backgroundColor:'red !important',
              boxShadow: '0 .5rem 1rem rgba(0,0,0,.15) !important',
              padding: '1rem !important'
            }}
          >
            <Row>
              <div className="col-md-12">
                <span className="f-18 f-w-700">${campaignData.totalRaised || 0}</span>
                <span className="f-w-600"> raised of ${campaignData.goal} goal</span>
              </div>
              <div className="col-md-12 pt-1">
                <Progress
                  color="primary"
                  value={raisedProgressBar}
                  className="sm-progress-bar"
                />
              </div>
            </Row>
            <Row className="pt-2">
              <div className="col-md-4">
                <Row>
                  <span className="f-12 f-w-700">{campaignData.donationsMadeIndicator || 0}</span>
                </Row>
                <Row>
                  <span className="f-12">donors</span>
                </Row>
              </div>
              <div className="col-md-4">
                <Row>
                  <span className="f-12 f-w-700">{campaignData.shares || 0}</span>
                </Row>
                <Row>
                  <span className="f-12">shares</span>
                </Row>
              </div>
              <div className="col-md-4">
              <Row>
                  <span className="f-12 f-w-700">{campaignData.followers || 0}</span>
                </Row>
                <Row>
                  <span className="f-12">followers</span>
                </Row>
              </div>
            </Row>
            <Row>
              <div className="col-md-12 pt-4">
                <Button
                className="w-100 f-14 p-2"
                color="primary"
                onClick={toggle}
                >
                  Share
                </Button>
              </div>
              <div className="col-md-12 pt-3">
                <Button
                  className="w-100 f-14 p-2"
                  color="primary"
                  onClick={onDonateButtonClicked}
                >
                  Donate now
                </Button>
              </div>
            </Row>
            <Row className="pt-4 text-left">
              <div className="col-md-12">
                <span className="u-pearl-number"><i className="txt-primary icofont icofont-time"></i></span>
                <span className="f-w-700 txt-primary"> { remainingDays }</span>
                <span className=""> days to finish</span>
              </div>
              <div className="col-md-12 pt-2">
                <span className="u-pearl-number"><i className="txt-primary icofont icofont-growth"></i></span>
                <span className="f-w-700 txt-primary"> {campaignData.donationsMadeIndicator || 0}</span>
                <span className=""> people just donated</span>
              </div>
            </Row>
          </Card>
        </div>
      </Row>

      
    <Modal isOpen={modal} className="modal-dialog-centered">
      <ModalHeader style={{ display: "inline" }}>
        <span className="font-weight-bold">Help by sharing</span>
        <button className="btn-close invisible" type="button" onClick={toggle}>
          <span aria-hidden="true" className="visible">×</span>
        </button>
      </ModalHeader>
          
      <ModalBody>
        <div className="f-16">
          Fundraisers shared on social networks raise up to 5x more.
        </div>
        
        <div className="contain p-2 mt-4 mb-4">
          <div className="text-center d-flex w-100 justify-content-between align-items-center">
            <span className="mr-2">Link</span>
            <div className="p-2 w-100">
              <Input
                className="form-control"
                type="text"
                placeholder="School motto"
                value={shareLink}
                readOnly
              />
            </div>
            <button
              className="btn btn-primary txt-white"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onCopyClicked}
            >
              { copied ? 'Copied' : 'Copy' }
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>


    </div>
  );
};

export default CampaignDetailsWidget;
