import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardBody, Media, Form, FormGroup, Input, InputGroup, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import errorImg from "../../../../assets/images/search-not-found.png";
import start_conversion from "../../../../assets/images/start-conversion.jpg";
import { getUserChats, saveChatMessage } from "../../data-helpers/chat-data-helpers";
import { acceptInvitationPendingFromUser, getUserInvitations, getUsersWithConnections, declineDonorConnection } from "../../data-helpers/donor-connections-data-helpers";
import { getCurrentAuthUser } from "../../data-helpers/user-data-helpers";
import { addSendMessageNotification, readCurrentNotifications } from "../../data-helpers/notifications-data-helpers";
import DonorInvitationOption from "../donor-invitation-option";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Chat = (props) => {
  const currentUserLoggedId = localStorage.getItem("userId");
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("activeConnections");
  const [contactedUsers, setContactedUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeChat, setActiveChat] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [savingMessage, setSavingMessage] = useState(false);
  const [donorReceivedInvitations, setDonorReceivedInvitations] = useState([]);
  const [donorSentInvitations, setDoSentingInvitations] = useState([]);
  const [donorActiveConnections, setDonorActiveConnections] = useState([]);
  const currentMessagesOfChatSelected = useRef(0);
  const [isUpdateChatRunning, setIsUpdateChatRunning] = useState(true);

  const getData = async () => {
    loadChatData();
  }

  const loadChatData = async () => {
    setIsUpdateChatRunning(false);

    const invitations = await getUserInvitations(currentUserLoggedId);
    const usersWithConnections = await getUsersWithConnections(invitations, currentUserLoggedId);
    const currentUserData = await getCurrentAuthUser();
    const allUsers = [
      {
        'id':currentUserData.id,
        'name': `${currentUserData.first_name} ${currentUserData.last_name}`,
        'image':currentUserData.image
      },
      ...usersWithConnections
    ];
    const userChats = await getUserChats(currentUserLoggedId);

    setAllUsers(allUsers);
    setChats(userChats); 
    await loadTabUserInfo(invitations, allUsers);

    const isMobileScreen = window.innerWidth <= 767;

    if (userChats.length > 0 && !selectedChat && !isMobileScreen) {
      const initialSelectedChat = userChats[0];
  
      setSelectedUser(allUsers.find((user) => user.id === initialSelectedChat.contactedUserId)); 
      setActiveChat(initialSelectedChat.contactedUserId); 
      setSelectedChat(initialSelectedChat); 
      readCurrentNotifications(initialSelectedChat.contactedUserId);
    } else if(selectedChat){
     
      const currentChat = userChats.find((x) => x.users.includes(currentUserLoggedId) && x.users.includes(activeChat));
      const areReceivedNewMessages= currentMessagesOfChatSelected.current !== currentChat.messages.length;

      onSelectChat(currentChat);

      if (areReceivedNewMessages) {
        setTimeout(function () { scrollDownPage(); }, 300);
      }
    }

    setIsUpdateChatRunning(true);
  };

  const loadTabUserInfo = async (invitations, allUsers) => {
    
    const donorReceivedInvitations = invitations.received
    .map((i) => {
      const userId = i.from_user_id;
      const userProps = allUsers.find((user) => user.id === userId);

     return { invitation: i, ...userProps }
    });
    setDonorReceivedInvitations(donorReceivedInvitations);

    const donorSentInvitations = invitations.sent
    .map((i) => {
      const userId = i.to_user_id;
      const userProps = allUsers.find((user) => user.id === userId);

      return { invitation: i, ...userProps }
    });
    setDoSentingInvitations(donorSentInvitations);

    const donorActiveConnections = invitations.active
      .map((i) => {
        const userId = i.from_user_id === currentUserLoggedId ? i.to_user_id : i.from_user_id;
        const userProps = allUsers.find((user) => user.id === userId);

        return { invitation: i, ...userProps }
      });
    setDonorActiveConnections(donorActiveConnections);
    setContactedUsers(donorActiveConnections);
  }

  const getUserInfo = (userId) => {
    return allUsers.find((user) => user.id === userId);
  }

  const onSelectChat = (chat) => {
    setSelectedUser(getUserInfo(chat.contactedUserId)); 
    setActiveChat(chat.contactedUserId); 
    setSelectedChat(chat); 
    readCurrentNotifications(chat.contactedUserId);
    setIsChatBoxOpen(true);

    currentMessagesOfChatSelected.current = chat.messages.length;
  }

  const changeChatClick = (e, selectedUserId) => {
    const currentChat = chats.find((x) => x.users.includes(currentUserLoggedId) && x.users.includes(selectedUserId));
    
    if (currentChat) {
      onSelectChat(currentChat);
    }

    setTimeout(function () { scrollDownPage(); }, 300);
  };

  const handleMessageChange = (message) => {
    setMessageInput(message);
  };

  const handleMessagePress = async (e) => {
    if (savingMessage || !activeChat || messageInput.length === 0) {
      return;
    }

    if (e.key === "Enter" || e === "send") {

      setSavingMessage(true);

      const newMessage = {
        "sender": currentUserLoggedId,
        "time":  new Date().toISOString(),
        "text": messageInput
      };

      const chatUpdated = {
        id: selectedChat.id,
        users: selectedChat.users,
        messages: [
          ...selectedChat.messages,
          newMessage
        ]
      };

      await saveChatMessage(chatUpdated);
      await addSendMessageNotification(activeChat);

      setChats(chats.map((c) => c.id === selectedChat.id ? { ...c, messages: selectedChat.messages } : c)); 
      onSelectChat({  ...selectedChat,  messages: chatUpdated.messages });
      setMessageInput("");
      setSavingMessage(false);
      setTimeout(function () { scrollDownPage(); }, 300);
    }
  };

  const onIgnoredReceivedInvitation = async (data) => {
    setDonorReceivedInvitations(current => {
      return current
        .filter(item => item.invitation.id !== data.invitation.id)
    });
    
    await declineDonorConnection(data.invitation.id);
  }

  const onAcceptedReceivedInvitation = async (data) => {
    setDonorReceivedInvitations(current => {
      return current
        .map(i => (i.invitation.id === data.invitation.id  ? { ...i, 'accepted': true } : i))
    });

    await acceptInvitationPendingFromUser(data.invitation);

    getData();
  }

  const scrollDownPage = () => {
    const chatHistoryContainer = document.querySelector(".chat-history");
    
    if (chatHistoryContainer) {
      const scrollingElement = (document.scrollingElement || document.body);

      scrollingElement.scrollBy({ top: scrollingElement.scrollHeight, behavior: "instant" });

      chatHistoryContainer.scrollBy({ top: chatHistoryContainer.scrollHeight, behavior: "instant" });
    }
  }

  const onCloseCurrentChat = () => {
    setIsChatBoxOpen(false);
    setSelectedUser(null); 
    setActiveChat(null); 
    setSelectedChat(null); 

    currentMessagesOfChatSelected.current = 0;
  }

  useInterval(() => {
    getData();

  }, isUpdateChatRunning ? 10000 : null);

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);

    getData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Inbox" title="My Connections" />
      <Container fluid={true}>
        <Row>
          <Nav className="border-tab-primary p-0" tabs>
            <NavItem>
              <NavLink
                href="#javascript"
                className={`${currentTab === "activeConnections" ? "active bg-primary txt-white" : ""} b-primary b-r-0 text-start`}
                onClick={(e) => { e.preventDefault(); setCurrentTab("activeConnections");}}
              >
                Active Connections
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#javascript"
                className={`${currentTab === "requestedConnections" ? "active bg-primary txt-white" : ""} b-primary b-r-0 text-start`}
                onClick={(e) => { e.preventDefault(); setCurrentTab("requestedConnections");}}
              >
                Invitations Received
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#javascript"
                className={`${currentTab === "pendingConnections" ? "active bg-primary txt-white" : ""} b-primary b-r-0 text-start`}
                onClick={(e) => { e.preventDefault(); setCurrentTab("pendingConnections");}}
              >
                Invitations Sent
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentTab}>
            <TabPane className="fade show" tabId="activeConnections">
              { allUsers && chats &&
              <Row>
                { (!isMobileScreen || !isChatBoxOpen) && 
                  <Col sm="12" className="call-chat-sidebar">
                  <Card>
                    <CardBody className="chat-body p-3">
                      <div className="chat-box">
                        {/* Chat left side Start */}
                        <div className="chat-left-aside">
                          <div className="people-list">
                            {contactedUsers.length > 0 ? (
                              <ul className={isMobileScreen ? "mh-100" : "list custom-scrollbar"}>
                                {contactedUsers
                                  .map((item, i) => {
                                    return (
                                      <li className={`clearfix ${activeChat === item.id ? "active" : ""}`} key={i} onClick={(e) => changeChatClick(e, item.id)}>
                                        <Link to={`${process.env.PUBLIC_URL}/user/profile/${item.id}`} alt="View profile">
                                          <img src={item.image} className="rounded-circle user-image" alt="" />
                                        </Link>
                                        <div className="about">
                                          <div className="name">{item.name}</div>
                                          {/* <div className="status">{item.status}</div> */}
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            ) : (
                              <Media className="img-fluid m-auto" src={errorImg} alt="" />
                            )}
                          </div>
                        </div>
                        {/* Chat left side Ends */}
                      </div>
                    </CardBody>
                  </Card>
                  </Col>
                }
                { (!isMobileScreen || isChatBoxOpen) && 
                  <Col className="call-chat-body">
                    <Card>
                      <CardBody className="p-0">
                        <Row className="chat-box">
                          {/* Chat right side start */}
                          <Col className="pe-0 chat-right-aside">
                            {/* chat start */}
                            <div className="chat">
                              {/* chat-header start */}
                              { selectedUser &&
                                <div className="chat-header clearfix">
                                  { isMobileScreen
                                  ? 
                                    <div className="chat-header clearfix">
                                      <div onClick={onCloseCurrentChat} alt="View profile">
                                        <div className="d-flex">
                                          <div className="f-24 p-2">
                                            <i className="fa fa-arrow-left"></i>
                                          </div>
                                          <div>
                                            <Media src={selectedUser.image} className="rounded-circle" alt="" />
                                            <div className="about">
                                              <div className="name">{selectedUser.name}</div>
                                              {/* <div className="status digits">{selectedUser.online ? "online" : selectedUser.lastSeenDate}</div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  : <div className="chat-header clearfix">
                                      <div>
                                        <Media src={selectedUser.image} className="rounded-circle" alt="" />
                                      </div>
                                      <div className="about">
                                        <div className="name">{selectedUser.name}</div>
                                        {/* <div className="status digits">{selectedUser.online ? "online" : selectedUser.lastSeenDate}</div> */}
                                      </div>
                                    </div>
                                  }
                                </div>
                              }
                              {/* chat-header end */}
                              <div className="chat-history chat-msg-box custom-scrollbar">
                              <ul>
                                  {selectedChat && selectedChat.messages.length > 0 ? (
                                    selectedChat.messages.map((item, index) => {
                                      const user = allUsers.find((x) => x.id === item.sender);
                                      return (
                                        <li key={index} className="clearfix">
                                          <div className={`message my-message ${item.sender !== currentUserLoggedId ? "bg-light" : "float-end"}`}>
                                            <Media src={user.image} className={`rounded-circle ${item.sender !== currentUserLoggedId ? "float-start" : "float-end"} chat-user-img img-30`} alt="" />
                                            <div className="message-data text-end">
                                              <span className="message-data-time">{item.timeFromNow}</span>
                                            </div>
                                            {item.text}
                                          </div>
                                        </li>
                                      );
                                    })
                                  ) : (
                                    <div className="text-center">
                                      <img className="img-fluid" src={start_conversion} alt="start conversion" />
                                    </div>
                                  )}
                                </ul>
                              </div>
                              {/* end chat-history */}
                              <div className="chat-message clearfix">
                                <Row>
                                  <Col xl="12" className="d-flex">                           
                                    <InputGroup className="text-box">
                                      <Input type="text" className="form-control input-txt-bx" placeholder="Type a message......" value={messageInput} onKeyPress={(e) => handleMessagePress(e)} onChange={(e) => handleMessageChange(e.target.value)} />
                                      <div addontype="append" className="input-group-append">
                                        <Button color="primary rounded-0" disabled={savingMessage} onClick={() => handleMessagePress("send")}>
                                          { !savingMessage ? 'SEND' : 'SENDING...' }
                                        </Button>
                                      </div>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </div>
                              {/* end chat-message */}
                              {/* chat end */}
                              {/* Chat right side ends */}
                            </div>
                          </Col>
                                    
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                }
              </Row>
              }
            </TabPane>
            <TabPane tabId="requestedConnections">
              <Row>
                {donorReceivedInvitations.length === 0 ? <div className="text text-muted">No invitations.</div> : ''}
                {donorReceivedInvitations.map((properties, i) => {
                  return (
                    <Col md="12" key={i}>
                      <DonorInvitationOption
                        {...properties}
                        onIgnoredClicked={() => onIgnoredReceivedInvitation(properties) }
                        onAcceptedClicked={() => onAcceptedReceivedInvitation(properties) }
                      />
                    </Col>
                  );
                })}
              </Row>
            </TabPane>
            <TabPane tabId="pendingConnections">
              <Row>
                {donorSentInvitations.length === 0 ? <div className="text text-muted">No invitations.</div> : ''}
                {donorSentInvitations.map((properties, i) => {
                  return (
                    <Col md="12" key={i}>
                      <DonorInvitationOption
                        {...properties}
                      />
                    </Col>
                  );
                })}
              </Row>
            </TabPane>
          </TabContent>
        </Row>
      </Container> 
    </Fragment>
  )
};

export default Chat;
