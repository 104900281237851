

import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { setThemes } from "../data/customizer/themes-helper";
import { isUserLoggedIn } from "src/component/alumnibuild/data-helpers/user-data-helpers";

const PrivateRoute = () => {
  const [currentUser, setCurrentUser] = useState(isUserLoggedIn());

  // eslint-disable-next-line
  const jwt_token = localStorage.getItem("token");
  const abortController = new AbortController();

  useEffect(() => {
    setThemes();

    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;

    return function cleanup() {
      abortController.abort();
    };
  }, [abortController]);

  return currentUser !== null || jwt_token ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;

