import React from "react";

export const MENUITEMS = [
  {
    title: "Home",
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: `${process.env.PUBLIC_URL}/dashboard`,
    active: false,
    type: "link",
    active: true
  },
 {
    title: "Explore",
    icon: <i className="pe-7s-search"></i>,
    type: "link",
    path: `${process.env.PUBLIC_URL}/explore`,
    active: false,
    bookmark: true
  },
  {
    title: "My Connections",
    icon: <i className="pe-7s-chat"></i>,
    type: "link",
    path: `${process.env.PUBLIC_URL}/my-connections`,
    active: false,
    bookmark: true
  },
  /* {
    title: "Community Hubs",
    icon: <i className="pe-7s-chat"></i>,
    type: "link",
    path: `${process.env.PUBLIC_URL}/`,
    active: false,
    bookmark: true
  }, */
  {
    title: "Notifications",
    icon: <i className="pe-7s-bell"></i>,
    type: "link",
    path: `${process.env.PUBLIC_URL}/notifications`,
    active: false,
    bookmark: true
  }
];
