export function sortByProperty (list, propertyName, isStringDateProperty) {
  if (isStringDateProperty) {
    return sortByDateProperty(list, propertyName);
  }

  return sortByStringProperty(list, propertyName);
}

function sortByStringProperty (list, propertyName) {
  return list.sort((a, b) => {
    const nameA = a[propertyName].toUpperCase();
    const nameB = b[propertyName].toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}

function sortByDateProperty (list, propertyName) {
  return list.sort(function(a, b) {
    const aDate = new Date(a[propertyName]);
    const bDate = new Date(b[propertyName]);

    return bDate - aDate;
  });
}


