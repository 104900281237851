import React, { useState } from "react";
import { Alert, Form, FormGroup, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { auth } from "./firebase";

const ResetPasswordActionHandler = (props) => {
  const [error, setError] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  
  const onResetPressed = () => {
    setError("");

    if (newPassword !== confirmedPassword) {
      setError("Password does not match.");

      return;
    }

    if (isProcessing || newPassword === "" || confirmedPassword === "") {
      return;
    }

    setIsProcessing(true);

    auth.verifyPasswordResetCode(props.actionCode).then(() => {
      auth.confirmPasswordReset(props.actionCode, newPassword).then((resp) => {
        setPasswordUpdated(true);
      }).catch((error) => {
        setError("An error occurred. The reset link might have expired or password is too weak");
        setIsProcessing(false);
        console.log("error:", error);
      });
    }).catch((error) => {
      setError('An error occurred. The code is malformed, expired, or has already been used. Please try to reset the password again');
      setIsProcessing(false);
      console.log("error:", error);
    });
  }

  return (
    <Form className="w-100">
      <div className="logo-wrapper">
        <a href="/">
          <img src={require("../../../assets/images/logo/nationbuild-icon.png")}/>
        </a>
      </div>

      { !passwordUpdated ? 
        <div>
          <div className="pb-4 pt-4">
            <h4>Reset your password</h4>
          </div>
          <p className="text-justify pt-4 f-14" style={{ textAlign: "justify"}}> 
            Set the new password for your account so you can log in and access to our platform.
          </p>

          <FormGroup>
            <Input className="form-control" type="password" placeholder="New password" name="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Input className="form-control" type="password" placeholder="Confirm password" name="confirm" value={confirmedPassword} onChange={(e) => setConfirmedPassword(e.target.value)} />
          </FormGroup>

          <FormGroup className="d-flex flex-wrap mt-3 mb-0">
            <Button color="primary d-block w-100" onClick={onResetPressed}>
              {isProcessing ? 'Updating password...' : 'Reset password' }
            </Button>
          </FormGroup>
        </div>
        :
        <div>
          <div className="pb-4 pt-4">
            <h4>Password updated</h4>
          </div>
          <hr />
          <p className="text-center pt-4 f-14" style={{ textAlign: "justify"}}> 
            Your password have been updated.
            Please use the link below to redirect to the login page.
          </p>
        </div>
        }

        { error && 
          <Alert color="danger">
            <p>{ error }</p>
          </Alert>
        }

        <div className="w-100">
          <Link color="primary d-block w-100" to={`${process.env.PUBLIC_URL}/login`}>
            Go to Login
          </Link>
        </div>
    </Form>
  );
};

export default ResetPasswordActionHandler;
