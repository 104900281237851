import { db } from "src/component/alumnibuild/components/firebase";
import { collection, addDoc, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { saveFile, getFile } from "./files-data-helpers";
import { getRemainingDays, getDurationFromMonths } from "../utils/date-utils";

export async function createCampaign(campaign) {
  const reference = collection(db, 'campaigns');
  const fields = campaign;
  const image = campaign.image;


  if (fields.image !== undefined) {
    delete fields.image;
  }

  if (fields.school_info !== undefined) {
    delete fields.school_info;
  }

  const result = await addDoc(reference, {
    ...fields,
    'created': new Date().toISOString(),
    'start_date': new Date().toISOString(),
    'end_date': getDurationFromMonths(fields.duration)
  });

  if (image) {
    await saveFile( `campaigns/${result.id}/campaign-image.jpg`, image);
  }
}

export async function getAllCampaigns() {
  const reference = collection(db, 'campaigns');
  const snapshot = await getDocs(query(reference));
  const result = [];
  
  if (snapshot.size !== 0) {
    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const image = await getFile( `campaigns/${doc.id}/campaign-image.jpg`);

      result.push({
        'id': doc.id,
        ...doc.data(),
        'image': image
      });
    }

    return result;
  } else {
    return undefined;
  }
}

export async function getSchoolLastCampaign(id) {
  // TODO: define how to get the active campaing, for now is only geeting the last campaign
  const reference = collection(db, 'campaigns');
  const snapshot = await getDocs(
    query(
      reference,
      where("school_id", "==", id)
    )
  );
  
  if (snapshot.size !== 0) {
    const lastCampaignIndex = snapshot.size - 1;
    const data = snapshot.docs[lastCampaignIndex].data();
    const id = snapshot.docs[lastCampaignIndex].id;
    const image = await getFile( `campaigns/${id}/campaign-image.jpg`);
    const currentDate = new Date();
    const startCampaignDate = new Date(data.start_date);
    const endCampaignDate = new Date(data.end_date);
    const remainingDays = currentDate < endCampaignDate ? getRemainingDays(startCampaignDate, endCampaignDate) : 0;
    const payments = await getCampaingPayments(id);
    const totalRaised = payments ? payments.reduce((acum, currentValue) => { return acum + currentValue.donation; }, 0) : 0;

    return {
      'id': id,
      'isActive': remainingDays > 0 ? true : false,
      ...data,
      'image': image,
      'totalRaised': totalRaised, // TODO: implement better strategy for this information
      'donationsMadeIndicator': payments ? payments.length : 0
    };
  }

  return undefined;
}

export async function getCampaignById(id) {
  const reference = doc(db, "campaigns", id);
  const snapshot = await getDoc(reference);

  if (snapshot.exists) {
    const data = snapshot.data();
    const image = await getFile( `campaigns/${id}/campaign-image.jpg`);
    const currentDate = new Date();
    const startCampaignDate = new Date(data.start_date);
    const endCampaignDate = new Date(data.end_date);
    const remainingDays = currentDate < endCampaignDate ? getRemainingDays(startCampaignDate, endCampaignDate) : 0;

    return {
      'id': id,
      'isActive': remainingDays > 0 ? true : false,
      ...data,
      'image': image
    };
  } else {
    return undefined
  }
}

export async function getCampaingPayments(campaignId) {
  const reference = collection(db, "payments");
  const snapshot = await getDocs(
    query(
      reference,
      where('campaignId', "==", campaignId)
    )
  );
  
  if (snapshot.size !== 0) {
    const documents = [];

    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const data = doc.data();
      
      documents.push({
        'id': doc.id,
        ...data 
      });
    }

    return documents;
  } else {
    return undefined;
  }
}

