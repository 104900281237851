import { db } from "src/component/alumnibuild/components/firebase";
import { collection, query, where, getDocs, documentId, limit, startAfter, orderBy } from "firebase/firestore";
import { getIdsOfUsersWithConnections } from "./donor-connections-data-helpers";
import { getUserInterestList, getUserImage, getCurrentAuthUser } from "./user-data-helpers";
import { sortByProperty } from "../utils/array-utils";

export async function getAlumniList() {
  const reference = collection(db, "users");
  const snapshot = await getDocs(
    query(
      reference,
      where("role", "==", "alumni"),
      where("isCompleteProfile", "==", true)
    )
  );

  const result = [];

  for (let index = 0; index < snapshot.size; index++) {
    const doc = snapshot.docs[index];
    const userId = doc.id;
    const image = await getUserImage(userId);

    result.push({
      'id': userId,
      ...doc.data(),
      'image': image
    });
  }

  return result;
}

function buildNotArrayFilterConstraints(filters) {
  if (!filters || filters && Object.entries(filters) === 0) {
    return [];  
  }

  let result = [];

  for (const [fieldName, fieldValue] of Object.entries(filters)) {
    if (fieldValue && !Array.isArray(fieldValue)) {
      result.push(where(fieldName, "==", fieldValue));
    }
  }

  return result;
}

function getDataWithArrayFiltersConditions(filters, data) {
  if (!filters || filters && Object.entries(filters) === 0) {
    return [];  
  }

  let filteredData = data;

  for (const [fieldName, fieldValue] of Object.entries(filters)) {
    if (Array.isArray(fieldValue) && fieldValue.length > 0) {
      const filterItems = (item) => {
        const valuesToCompare = item[fieldName] || [];

        return fieldValue.some(value => valuesToCompare.includes(value))
      }

      filteredData.push(...data.filter(filterItems));
    }
  }

  const listWithoutDuplicates = [...new Set(filteredData)];

  return listWithoutDuplicates;
}

export async function getRegisteredUsersDataFiltered(currentUserId, filters, lastVisibleDoc = undefined) {
  let filterConstraints = buildNotArrayFilterConstraints(filters);
  let queryProps = [
    collection(db, "users"),
    where("isCompleteProfile", "==", true),
    where("role", "==", "alumni"),
    where(documentId(), "!=", currentUserId)
  ];

  if (filterConstraints.length > 0) {
    queryProps.push(...filterConstraints);
  }

  if (lastVisibleDoc) {
    queryProps.push(startAfter(lastVisibleDoc.id));
  }

  const snapshot = await getDocs(query(...queryProps));
  const result = [];
  const idsOfUsersFromInvitations = await getIdsOfUsersWithConnections(currentUserId);
  const filteredData = getDataWithArrayFiltersConditions(filters, snapshot.docs.map(doc => ({ ...doc.data(), 'id': doc.id })));
  const maxResultsData = filteredData.slice(0, 100);

  for (let index = 0; index < maxResultsData.length; index++) {
    const userData = maxResultsData[index];
    const image = await getUserImage(userData.id);

    result.push({
        ...userData,
       'image': image,
       'interest': getUserInterestList(userData),
       'isAlreadyAConnection': idsOfUsersFromInvitations.includes(userData.id),
    });
  }
  

  const isStringDateProperty = true;

  return sortByProperty(result, "created", isStringDateProperty); // TODO: add pagination
}

export async function getDonorListBaseOnInterest(currentUserId, lastVisibleDoc = undefined) {
  // TODO: refactor this logic
  const currentAuthUser = await getCurrentAuthUser();
  const idsOfUsersFromInvitations = await getIdsOfUsersWithConnections(currentAuthUser.id);
  const filters = {
    "looking_to": currentAuthUser.looking_to || undefined,
    "regions_of_interest": currentAuthUser.regions_of_interest || undefined,
    "industries_of_interest": currentAuthUser.industries_of_interest || undefined,
    "research_themes_of_interest": currentAuthUser.research_themes_of_interest || undefined
}
  
  let filterConstraints = buildNotArrayFilterConstraints(filters);
  let queryProps = [
    collection(db, "users"),
    orderBy('__name__'),
    where("isCompleteProfile", "==", true),
    where("role", "==", "alumni"),
    where(documentId(), "not-in", [currentUserId, ...idsOfUsersFromInvitations]),
  ];

  if (filterConstraints.length > 0) {
    queryProps.push(...filterConstraints);
  }

  const snapshot = await getDocs(query(...queryProps));
  const result = [];
  const matchingDatabaseResults = snapshot.docs.map(doc => ({ ...doc.data(), 'id': doc.id }));
  const dataFiltered = getDataWithArrayFiltersConditions(filters, matchingDatabaseResults);
  const totalExpectedUsers = 4;
  const dataBasedOnMatchingProfile = dataFiltered.slice(0, totalExpectedUsers);

  if (dataBasedOnMatchingProfile.length < totalExpectedUsers) { 
    queryProps = [
      collection(db, "users"),
      orderBy('__name__'),
      where("isCompleteProfile", "==", true),
      where("role", "==", "alumni"),
      where(documentId(), "not-in", [currentUserId, ...idsOfUsersFromInvitations, ...dataBasedOnMatchingProfile.map(user => user.id)]),
    ];
    
    const otherUserData = await getDocs(
      query(...queryProps, limit(totalExpectedUsers - dataBasedOnMatchingProfile.length))
    );

    dataBasedOnMatchingProfile.push(...otherUserData.docs.map(doc => ({ ...doc.data(), 'id': doc.id })));
  }

  for (let index = 0; index < dataBasedOnMatchingProfile.length; index++) {
    const userData = dataBasedOnMatchingProfile[index];
    const image = await getUserImage(userData.id);

    result.push({
      ...userData,
      'image': image,
      'interest': getUserInterestList(userData)
    });
  }
  
  return result; // TODO: add pagination
}
