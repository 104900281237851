import React from "react";

import Userprofile from "../component/alumnibuild/components/user-profile";
import Explorer from "../component/alumnibuild/components/explorer/explorer";
import CampaignNew from "../component/alumnibuild/components/campaign-new";
import SchoolDetails from "../component/alumnibuild/components/school-details";
import Alumni from "../component/alumnibuild/components/alumni";
import AlumniProfileDetails from "../component/alumnibuild/components/alumni-profile-details";
import CampaignDonate from "../component/alumnibuild/components/campaign-donate";
import CampaignDonateReceipt from "../component/alumnibuild/components/campaign-donate-receipt";
import Dashboard from "../component/alumnibuild/components/dashboard";
import ChatApp from "../component/alumnibuild/components/chat-app/chat";
import DonorDiscoverProfile from "../component/alumnibuild/components/donor-discover-profile";
import Notifications from "../component/alumnibuild/components/notifications";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Dashboard />,
  },
/*   {
    path: `${process.env.PUBLIC_URL}/schools`,
    Component: <Schools />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/campaigns/new`,
    Component: <CampaignNew />,
    
  },
  {
    path: `${process.env.PUBLIC_URL}/school/details/:id`,
    Component: <SchoolDetails />,
  }, */ 
  {
    path: `${process.env.PUBLIC_URL}/user/edit/profile`,
    Component: <Userprofile />,
  },
/*   {
    path: `${process.env.PUBLIC_URL}/donors`,
    Component: <Alumni />,
  }, */
  {
    path: `${process.env.PUBLIC_URL}/user/profile/:id`,
    Component: <AlumniProfileDetails />,
  },
/*   {
    path: `${process.env.PUBLIC_URL}/campaign/donate/:id`,
    Component: <CampaignDonate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/campaign/donate/receipt/:id`,
    Component: <CampaignDonateReceipt />,
  }, */
  { 
    path: `${process.env.PUBLIC_URL}/my-connections`,
    Component: <ChatApp />
  },
  { 
    path: `${process.env.PUBLIC_URL}/explore`,
    Component: <Explorer />
  },
/*   {
    path: `${process.env.PUBLIC_URL}/donors/discover/:id`,
    Component: <DonorDiscoverProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/donors/discover`,
    Component: <DonorDiscoverProfile />,
  }, */
  {
    path: `${process.env.PUBLIC_URL}/notifications`,
    Component: <Notifications />,
  },
]
