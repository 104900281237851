import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody, Media, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { updateUserData } from "../data-helpers/user-data-helpers";
import fallbackImage from "../../../assets/images/user/2.jpg";
import { useNavigate } from "react-router-dom";
import { getSchoolLevelsData, getSchoolsData, updateSchoolData } from "../data-helpers/schools-data-helpers";

const SchoolProfile = (props) => {
  const [user, setUser] = useState({ ...props  });
  const [message, setMessage] = useState();
  const [currentImage, setCurrentImage] = useState(user.image ? user.image : fallbackImage);
  const [newImage, setNewImage] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [schoolLevels, setSchoolLevels] = useState(undefined);
  const [schools, setSchools] = useState(undefined);
  const history = useNavigate();

  const onImageChanged = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) === null) {
      return;
    }

    setNewImage(file);

    console.log(file);
    
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      setCurrentImage(reader.result);
    }
  }

  const onAdministratoFirstNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, first_name: event.target.value }));
  };

  const onAdministratoLastNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, last_name: event.target.value }));
  };

  const onSchoolMottoChanged = (event) => {
    setUser((prevState) => ({ ...prevState, school_motto: event.target.value }));
  };

  const onSchoolVisionChanged = (event) => {
    setUser((prevState) => ({ ...prevState, school_vision: event.target.value }));
  };

  const onAccountNameChanged = (event) => {
    setUser((prevState) => ({ ...prevState, account_name: event.target.value }));
  };

  const onAccountNumberChanged = (event) => {
    setUser((prevState) => ({ ...prevState, account_number: event.target.value }));
  };

  const onAddressLine1Changed = (event) => {
    setUser((prevState) => ({ ...prevState, address_line_1: event.target.value }));
  };

  const onAddressLine2Changed = (event) => {
    setUser((prevState) => ({ ...prevState, address_line_2: event.target.value }));
  };

  const onZipCodeChanged = (event) => {
    setUser((prevState) => ({ ...prevState, zip_code: event.target.value }));
  };

  const onSchoolChanged = (event) => {
    setUser((prevState) => ({ ...prevState, school_name: event.target.value }));
  };

  const onSchoolLevelChanged = (event) => {
    const level = event.target.value;

    setUser((prevState) => ({ ...prevState, school_level: level }));

    getSchools(level);
  };
  
  const getSchoolLevels = async () => {
    const data = await getSchoolLevelsData();

    setSchoolLevels(()=>[ ...data ]);
  };

  const getSchools = async (level) => {
    const data = await getSchoolsData(level);

    setSchools(()=>[ ...data ]);
  };
  
  const onSchoolEmailChanged = (event) => {
    setUser((prevState) => ({ ...prevState, school_email: event.target.value }));
  };

  const onSubmitted = async (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);

    try {
      await updateUserData(user.id, {
        'first_name': user.first_name,
        'last_name': user.last_name
      });

      await updateSchoolData(user.school_id, {
        'motto': user.school_motto,
        'vision': user.school_vision,
        'newImage': newImage ? newImage: undefined
      });      

      setMessage("Information updated successfully.");

      history(`${process.env.PUBLIC_URL}/campaigns/new`);
    } catch (error) {
      setMessage("An error occurred while updating the information.");
      setIsButtonDisabled(false);

      console.log("error:", error);
    }
  };

  const onCancelPressed = async (event) => {
    event.preventDefault();

    history(`${process.env.PUBLIC_URL}/campaigns/new`);
  };

  useEffect(() => {
    getSchoolLevels();
    getSchools(user.school_level);
  }, []);

  return (
    <Card className="edit-profile">
      <CardHeader>
        <h4 className="card-title mb-0">Profile</h4>
        <div className="card-options">
          <a className="card-options-collapse" href="#javascript">
            <i className="fe fe-chevron-up"></i>
          </a>
          <a className="card-options-remove" href="#javascript">
            <i className="fe fe-x"></i>
          </a>
        </div>
      </CardHeader>
      <CardBody>
      <Row className="mb-4 my-auto">
            <div className="col-auto">
              <div className="user-image">
                <div className="avatar">
                  <Media
                    body
                    alt=""
                    src={currentImage}
                    data-intro="This is Profile image"
                  />
                </div>
                <div
                  className="icon-wrapper"
                  data-intro="Change Profile image here"
                >
                  <i className="icofont icofont-pencil-alt-5">
                    <input className="upload" type="file" onChange={onImageChanged} />
                  </i>
                </div>
              </div>
            </div>
      </Row>
      <Row>
          <div className="col-md-12">
            <Label className="form-label">School information</Label>
          </div>
          <div className="col-md-12">
            <FormGroup>
              <Label className="form-label">Level</Label>
              <Input
                type="select"
                className="form-control"
                onChange={onSchoolLevelChanged}
                value={user.school_level}
                disabled
              >
                <option value="">
                 Select a school level
                </option>
                {schoolLevels && schoolLevels.map((level, index) => (
                  <option key={index} value={level.name}>
                    {level.description}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div className="col-md-12">
            <FormGroup>
              <Label className="form-label">Name</Label>
              <Input
                type="select"
                className="form-control"
                onChange={onSchoolChanged}
                value={user.school_name}
                disabled
              >
                <option value="">
                 Select a school
                </option>
                {schools && schools.map((school, index) => (
                  <option key={index} value={school.id}>
                    {school.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div className="col-md-12">
            <FormGroup>
              <Label className="form-label">Email-Address</Label>
              <Input
                className="form-control"
                type="email"
                onChange={onSchoolEmailChanged}
                value={user.school_email}
                disabled
              />
            </FormGroup>
          </div>
          <div className="col-md-12">
            <FormGroup>
              <Label className="form-label">Motto</Label>
              <Input
                className="form-control"
                type="text"
                placeholder="School motto"
                value={user.school_motto}
                onChange={onSchoolMottoChanged}
              />
            </FormGroup>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-0">
              <Label className="form-label">Vision</Label>
              <Input
                type="textarea"
                rows="5"
                placeholder="School Vision"
                value={user.school_vision}
                onChange={onSchoolVisionChanged}
              />
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <div className="col-md-12">
            <Label className="form-label">Page Administrator</Label>
          </div>
          <div className="col-sm-6 col-md-6">
            <FormGroup>
              <Label className="form-label">First Name</Label>
              <Input
                className="form-control"
                type="text"
                placeholder="First Name"
                value={user.first_name}
                onChange={onAdministratoFirstNameChanged}
              />
            </FormGroup>
          </div>
          <div className="col-sm-6 col-md-6">
            <FormGroup>
              <Label className="form-label">Last Name</Label>
              <Input
                className="form-control"
                type="text"
                placeholder="Last name"
                value={user.last_name}
                onChange={onAdministratoLastNameChanged}
              />
            </FormGroup>
          </div>
          <div className="col-md-12">
            <FormGroup>
              <Label className="form-label">Email-Address</Label>
              <Input
                className="form-control"
                type="email"
                disabled
                value={user.email}
              />
            </FormGroup>
          </div>
          { message &&
            <div className="col-md-12 pt-2">
              <Alert color="info">
                <p>{message}</p>
              </Alert>
            </div>
          }
          <div className="text-end">
            <button className="btn btn-primary mt-4" type="submit" disabled={isButtonDisabled} onClick={onSubmitted}>
              Update
            </button>
            <button className="btn btn-light mt-4" type="submit" onClick={onCancelPressed}>
              Cancel
            </button>
          </div>
      </Row>
      </CardBody>
    </Card>
  );
};

export default SchoolProfile;
