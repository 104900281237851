import { db } from "src/component/alumnibuild/components/firebase";
import { collection, query, where, and, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";
import { saveFile, getFile } from "./files-data-helpers";
import { sortByProperty } from "../utils/array-utils";

export function getSchoolLevelsData() {
  // TODO: must be in the database
  return [
    {
      'name': 'primary',
      'description': 'Primary level'
    },
    {
      'name': 'secondary',
      'description': 'Secondary level'
    }
  ]
}

export function getInstitutionsIndustriesData() {
  // TODO: must be in the database
  return [
    {
      'name': 'Education',
    },
    {
      'name': 'Health',
    },
    {
      'name': 'Social Services',
    }
  ]
}

function getLevelDescription(level) {
  const schoolLevelsData = getSchoolLevelsData();
  const found = schoolLevelsData.find((l) => l.name === level);

  return found ? found['description'] : level;
}

export async function getRegisteredSchoolsData() {
  const reference = collection(db, "schools");
  const queryDocs = query(reference,where("isCompleteProfile", "==", true));
  const snapshot = await getDocs(queryDocs);

  if (snapshot.size !== 0) {
    const documents = [];

    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const data = doc.data();
      const image = ''; //TODO: review case dont have a image await getFile( `schools/${doc.id}/profile-image.jpg`);
      const levelDescription = getLevelDescription(data.level);

      documents.push({
        'id': doc.id,
        'image': image || '' ,
        'levelDescription': levelDescription,
        ...data 
      });
    }

    return sortByProperty(documents, "name");
  } else {
    return [];
  }
}

export async function getRegisteredSchoolsDataFiltered(filters) {
  let results = await getRegisteredSchoolsData();

  if (filters.hasOwnProperty('schoolName')) {
    results = results.filter((school) => school.name.match(new RegExp(filters.schoolName + '.*', 'gi')));
  }

  if (filters.hasOwnProperty('primaryLevel') && filters['primaryLevel'] && !filters.hasOwnProperty('secondaryLevel')) {
    results = results.filter((school) => school.level === 'primary');
  }
  
  if (filters.hasOwnProperty('secondaryLevel') && filters['secondaryLevel'] && !filters.hasOwnProperty('primaryLevel')) {
    results = results.filter((school) => school.level === 'secondary');
  }

  if (filters.hasOwnProperty('locations') && filters['locations'].length > 0) {
    results = results.filter((school) => filters['locations'].includes(school.country));
  }

  return results;
}

export async function getSchoolsData(country, level = undefined) {
  let queryDocs;

  const reference = collection(db, "schools");

  if (level !== undefined) {
    queryDocs = query(
      reference,
      where("country", "==", country),
      where("level", "==", level)
    );
  } else {
    queryDocs = query(reference, where("country", "==", country));
  }

  const snapshot = await getDocs(queryDocs);

  if (snapshot.size !== 0) {
    const documents = [];

    for (let index = 0; index < snapshot.size; index++) {
      const doc = snapshot.docs[index];
      const data = doc.data();
      const image = ''; //TODO: review case dont have a image await getFile( `schools/${doc.id}/profile-image.jpg`);
      const levelDescription = getLevelDescription(data.level);

      documents.push({
        'id': doc.id,
        'image': image || '' ,
        'levelDescription': levelDescription,
        ...data });
    }

    return sortByProperty(documents, "name"); // TODO: sort by database
  } else {
    return undefined;
  }
}

export async function getSchoolById(id) {
  const reference = doc(db, "schools", id);
  const snaphot = await getDoc(reference);
  
  if (snaphot.exists) {
    const image = await getFile( `schools/${id}/profile-image.jpg`);
    const data = snaphot.data();
    const levelDescription = getLevelDescription(data.level);

    return {
      ...data,
      'image': image || '',
      'levelDescription': levelDescription,
      'id': id,
    };
  } else {
    return undefined
  }
}


export async function getSchoolByField(fieldName, fieldValue) {
  const reference = collection(db, "schools");
  const snapshot = await getDocs(
    query(
      reference,
      where(fieldName, "==", fieldValue)
    )
  );

  if (snapshot.size !== 0) {
    const id = snapshot.docs[0].id;
    const image = await getFile( `schools/${id}/profile-image.jpg`);
    const data = snapshot.docs[0].data();
    const levelDescription = getLevelDescription(data.level);

    return {
      ...data,
      'image': image || '',
      'levelDescription': levelDescription,
      'id': id,
    };
  } else {
    return undefined;
  }
}

export async function updateSchoolData(school) {
  let fields = school;

  if (fields.image) {
    await saveFile( `schools/${fields.id}/profile-image.jpg`, fields.image);

    delete fields.image;
  }

  const reference = doc(db, "schools", fields.id);

  delete fields.id;

  return updateDoc(reference, { ...fields, 'updated': new Date().toISOString() });
}
