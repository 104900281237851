import React, { useState, useEffect } from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from "reactstrap";
import "./home.scss";
import whiteLogo from "../../../assets/images/logo/nationbuild-logo-white.png";
import defaultLogo from "../../../assets/images/logo/nationbuild-logo.png";
import CarouselWidget from "./carousel-widget";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [isNavbarScrolled, setIsNavbarScrolled] = useState(false);
  const [logo, setLogo] = useState(whiteLogo);

  const campaigns = [
    {
      'image': 'campaign_1.jpg',
      'country': 'Antigua',
      'sector': 'Education',
      'institution': 'McChesney George Secondary',
      'title': 'Retrofitting School Science Lab',
      'raised': '45,000',
      'goal': '63,000',
      'progress': ((45 / 63) * 100).toFixed(), 
      'deadline': '3 months'
    },
    {
      'image': 'campaign_2.jpg',
      'country': 'Saint Lucia',
      'sector': 'Health',
      'institution': 'Millennium Heights Medical Complex',
      'title': '50 beds',
      'raised': '2,500',
      'goal': '9,500',
      'progress': ((2.5 / 9.5) * 100).toFixed(), 
      'deadline': '3 months'
    },
    {
      'image': 'campaign_3.jpg',
      'country': 'Saint Lucia',
      'sector': 'Education',
      'institution': 'Center for Adolescent Renewal and Education (CARE)',
      'title': 'Ceramic Training: Four Pottery Wheels, Materials and Teacher Training',
      'raised': '7,000',
      'goal': '10,000',
      'progress': ((7 / 10) * 100).toFixed(), 
      'deadline': '2 month'
    },
    {
      'image': 'campaign_4.jpg',
      'country': 'Saint Lucia',
      'sector': 'Social Services',
      'institution': 'Boys Training Center',
      'title': 'Crisis Intervention Support: Capacity Building for Social Workers',
      'raised': '2,500',
      'goal': '6,000',
      'progress': ((2.5 / 6) * 100).toFixed(), 
      'deadline': '2 month'
    }
  ]

  const carouselItems = [
    {
      src: require('../../../assets/images/public-site/hero-image-1.jpg'),
      altText: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      caption: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      key: 1,
    },
    {
      src: require('../../../assets/images/public-site/hero-image-2.jpg'),
      altText: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      caption: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      key: 2,
    },
    {
      src: require('../../../assets/images/public-site/hero-image-3.jpg'),
      altText: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      caption: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      key: 3,
    },
    {
      src: require('../../../assets/images/public-site/hero-image-4.jpg'),
      altText: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      caption: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      key: 4,
    },
    {
      src: require('../../../assets/images/public-site/hero-image-5.jpg'),
      altText: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      caption: 'Connecting Thinkers and Doers in the Diaspora and at Home for Good',
      key: 5
    }
  ];
  
  const checkNavBarPosition = () => {
    const navBarHeight = 60;
    const isNavbarScrolled = window.scrollY >= navBarHeight;

    setLogo(isNavbarScrolled ? defaultLogo : whiteLogo);
    setIsNavbarScrolled(isNavbarScrolled);
  }

  useEffect(() => {
    checkNavBarPosition();

    window.addEventListener("scroll", checkNavBarPosition);
  })

  return (
    <div className="home">
      <Navbar className={isNavbarScrolled ? "home__navbar home__navbar--scrolled" : "home__navbar"} expand="sm" fixed="top">
        <NavbarBrand href="/">
          <img className="home__logo img-fluid" src={logo} alt="NationBuild Logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <a className="home__button text-white btn me-1 mt-1" href="/login" target="_blank">Log in</a>
            </NavItem>
            <NavItem>
              <a className="home__button text-white btn mt-1" href="/signup" target="_blank">Sign up</a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

      <CarouselWidget items={carouselItems}/>

      <div className="home__about-us pt-0">
        <div className="home__about-us-container row mt-5 mb-2">
          <div className="col-lg-5 col-sm-6 mb-4 mb-md-1 home__about-us-image">
          </div>

          <div className="col-lg-7 col-sm-6 mb-4 mb-md-1 pl-0">
            <div className="home__about-us-text h-100 p-4 ps-lg-5 text-white d-flex align-items-center"
            >
              <div className="pe-lg-5">
                <h3 id="who-we-are" className="fw-bold mb-4"><a className="txt-white" href="#who-we-are">Who We Are?</a></h3>
                <p className="f-18">
                  NationBuild is a community networking hub where diaspora and local citizens come together to connect, collaborate and exchange information for the betterment of home countries. Meet like-minded thinkers and doers from various backgrounds, find a business or research partner, a co-founder, or a new hire; engage and support interesting people and organizations doing really cool work. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="home__how-support row">
        <div className="col-lg-12 text-center">
          <h3 id="how-does-it-work" className="home__how-support-title pt-5 pb-3 fw-bold mb-4 text-start"><a href="#how-does-it-work">How Does It Work?</a></h3>
        </div>
        <div className="col-lg-4 col-sm-4 mb-4 line">
          <div className="w-100 f-20 text-center icon-offsite">
            <span className="circle">
              <i className="fa fa-user-plus icon-lg icon-yellow icon-bg-yellow icon-bg-circle mb-3"></i>
            </span>
          </div>
          <h4 className="home__how-support-title mt-4 mb-3 fw-bold text-center">Sign Up</h4>
          <p className="f-16 ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2">Complete your profile to explore global community members who share similar areas and regions of interest.</p>
        </div>
        <div className="col-lg-4 col-sm-4 mb-4 line">
          <div className="w-100 f-20 text-center icon-offsite">
            <span className="circle">
              <i className="fa fa-users icon-lg icon-purple icon-bg-purple icon-bg-circle mb-3"></i>
            </span>
          </div>
          <h4 className="home__how-support-title mt-4 mb-3 fw-bold text-center">Connect</h4>
          <p className="f-16 ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2">Enjoy meeting and rediscovering new and old connections with similar passions for key industries in your home country and region. Join and participate in community hubs dedicated to your region of interest; thematic areas; and hobbies.</p>
        </div>
        <div className="col-lg-4 col-sm-4 mb-4 line">
          <div className="w-100 f-20 text-center icon-offsite">
            <span className="circle">
              <i className="fa fa-check-circle icon-lg icon-primary icon-bg-primary icon-bg-circle mb-3"></i>
            </span>
          </div>
          <h4 className="home__how-support-title mt-4 mb-3 fw-bold text-center">Make an Impact</h4>
          <p className="f-16 ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2">Connect, collaborate, exchange knowledge and support amazing people just like you, stewarding great causes, businesses, and research efforts. Find new talent and key associations, and have a great time sharing your knowledge and expertise for good.</p>
        </div>
      </div>
      <div className="home__pictures text-center row">
        <div className="col-md-3 col-sm-6 col-6 p-0">
          <img className="card-img-top" src={require(`../../../assets/images/public-site/natiobuild-pics-1.jpg`)} />
        </div>
        <div className="col-md-3 col-sm-6 col-6 p-0">
          <img className="card-img-top" src={require(`../../../assets/images/public-site/natiobuild-pics-2.jpg`)} />
        </div>
        <div className="col-md-3 col-sm-6 col-6 p-0">
          <img className="card-img-top" src={require(`../../../assets/images/public-site/natiobuild-pics-3.jpg`)} />
        </div>
        <div className="col-md-3 col-sm-6 col-6 p-0">
          <img className="card-img-top" src={require(`../../../assets/images/public-site/natiobuild-pics-4.jpg`)} />
        </div>
      </div>
    {/*   <div className="home__sample-campaigns-title text-center">
        <h3 className="p-0 m-0 font-weight-bold">Sample Campaigns</h3>
      </div>

      <div className="home__sample-campaigns card-group">
        {campaigns.map((campaign, i) => {
          return (
            <div className="home__campaign-card card" key={i}>
              <img 
                src={require(`../../../assets/images/public-site/${campaign.image}`)}
                alt={campaign.title}
              />
              <div className="home__campaign-details card-body">
                <div className="home__campaign-country text-muted">
                  <i className="fa fa-globe"></i> { campaign.country }
                </div>
                <div className="home__campaign-country text-muted">
                  <i className="fa fa-institution"></i> { campaign.sector }
                </div>
                <div className="home__campaign-title">{ campaign.title }</div>
                <div className="home__campaign-progress progress">
                  <div className="progress-bar bg-success" role="progressbar" style={{width: `${campaign.progress}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="pt-2 pb-4">
                  <div className="row">
                    <div className="col-md-9 font-weight-bold">
                      USD ${ campaign.raised }
                    </div> 
                    <div className="col font-weight-bold text-end">
                      {campaign.progress}%
                    </div> 
                  </div>
                  <div className="text-muted">
                    <small>raised of USD ${ campaign.goal }</small>
                  </div>
                </div>
                
                <div className="home__campaign-last-section">
                  <div className="row text-muted card-text" >
                    <small> By { campaign.institution }</small>
                  </div>
                  <div className="row text-muted card-text">
                    <small>{ campaign.deadline } deadline</small>
                  </div>
                </div>
              </div>
              <div className="text-center p-2">
                <div className="home__campaign-donate-button btn me-3 mb-2">Donate</div>
              </div>
            </div>
          );
        })}
      </div> */}

      <div className="home__call-section row text-center">
        <div className="col-md-12 d-flex justify-content-center align-items-center flex-column">
          <h3 className="font-weight-bold mb-3">Ready to Become a NationBuilder?</h3>
          <p className="home__builder-text mb-3 f-16">
            Connect with like-minded social impact champions in your industry and area of interest! 
          </p>
        </div>
        <div className="home__builder-actions row f-20 pb-2">
          <div className="col-xs-6 col-sm-3"> <i className="home__check-icon fa fa-check-circle"></i> Do Good</div>
          <div className="col-xs-6 col-sm-3"> <i className="home__check-icon fa fa-check-circle"></i> Have Fun</div>
          <div className="col-xs-6 col-sm-3"> <i className="home__check-icon fa fa-check-circle"></i> Connect Meaningfully</div>
          <div className="col-xs-6 col-sm-3"> <i className="home__check-icon fa fa-check-circle"></i> Build and Make an Impact</div>
        </div>
        <div className="pb-2 pt-3">
          <a className="home__button home__button--warning btn me-3" href="/signup" target="_blank">Join now</a>
        </div>
      </div>

      <div className="home__footer text-dark">
        <div className="d-flex flex-wrap flex-row justify-content-around align-items-center pt-2">
            <a className="text-dark" href="mailto:hello@nationbuild.io?subject=Contact Form Nationbuild">
              Contact Us
            </a>
            <a className="text-dark" href={`${process.env.PUBLIC_URL}/terms-and-conditions`} target="_blank">
              Terms and Conditions
            </a>
            <a className="text-dark" href={`${process.env.PUBLIC_URL}/privacy-policy`} target="_blank">
              Privacy Policy
            </a>
        </div>
        <div className="text-center pt-4 pb-2">
          Copyright &copy; {new Date().getFullYear()} NationBuild, Inc. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Home;
