import React, { Fragment, useState, useEffect } from "react";
import { Alert, Row, FormGroup, Label, Input, Col, CardBody, Container, Popover, PopoverBody } from "reactstrap";
import ImageCropper from './image-cropper/image-cropper';
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Typeahead } from 'react-bootstrap-typeahead';
import { updateUserData } from "../data-helpers/user-data-helpers";
import { getIndustriesData } from "../data-helpers/industries-data-helpers";
import { useNavigate } from "react-router-dom";
import { getCountriesData } from "../data-helpers/countries-data-helpers";
import { getHobbiesData } from "../data-helpers/hobbies-data-helpers";
import { getRegionsData } from "../data-helpers/regions-data-helpers";
import { getLookingToData } from "../data-helpers/looking-to-data-helpers";
import { getResearchThemesData } from "../data-helpers/research-themes-data-helpers";
import { log } from "../data-helpers/logs-data-helpers";

const AlumniProfile = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...props });
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);
  const [countryOfOriginSelected, setCountryOfOriginSelected] = useState([]);
  const [regionsOfInterestSelected, setRegionsOfInterestSelected] = useState([]);
  const [industriesOfInterestSelected, setIndustriesOfInterestSelected] = useState([]);
  const [hobbiesOfInterestSelected, setHobbiesOfInterestSelected] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [lookingToOptions, setLookingToOptions] = useState([]);
  const [lookingToOptionsSelected, setLookingToOptionsSelected] = useState([]);
  const [popoverInteractInfo, setPopoverInteractInfo] = useState(false);
  const [popoverInterestInfo, setPopoverInterestInfo] = useState(false);
  const [researchThemes, setResearchThemes] = useState([]);
  const [researchThemesOfInterestSelected, setResearchThemesOfInterestSelected] = useState([]);
  const [error, setError] = useState(null);

  const history = useNavigate();

  const toggleInteractInfo = () => setPopoverInteractInfo(!popoverInteractInfo);
  const toggleInterestInfo = () => setPopoverInterestInfo(!popoverInterestInfo);

  const getCountries = async (level) => {
    const countriesData = await getCountriesData(level);
    const countrySelected = countriesData.find((country) => country.name === props.country);
    const countryOfOriginSelected = countriesData.find((country) => country.name === props.country_of_origin);

    setCountries(()=>[ ...countriesData ]);
    setCountrySelected(countrySelected ? [countrySelected] : []);
    setCountryOfOriginSelected(countryOfOriginSelected ? [countryOfOriginSelected] : []);
  };

  const getIndustries = async () => {
    const data = await getIndustriesData();

    setIndustries(()=>[ ...data ]);

    let selections = [];

    if (props.industries_of_interest) {
      selections = data.filter((c)=> (props.industries_of_interest.find((i) => i === c.name)));
    } 

    setIndustriesOfInterestSelected(selections);
  };

  const getHobbies = async () => {
    const data = await getHobbiesData();

    setHobbies(()=>[ ...data ]);

    let selections = [];

    if (props.hobbies_of_interest) {
      selections = data.filter((h)=> (props.hobbies_of_interest.find((i) => i === h.name)));
    } 

    setHobbiesOfInterestSelected(selections);
  };

  const getRegions = async () => {
    const data = await getRegionsData();

    setRegions(()=>[ ...data ]);

    let selections = [];

    if (props.regions_of_interest) {
      selections = data.filter((h)=> (props.regions_of_interest.find((i) => i === h.name)));
    } 

    setRegionsOfInterestSelected(selections);
  };

  const getLookingToOptions = async () => {
    const data = await getLookingToData();

    setLookingToOptions(()=>[ ...data ]);

    let selections = [];

    if (props.looking_to) {
      selections = data.filter((h)=> (props.looking_to.find((i) => i === h.name)));
    } 

    setLookingToOptionsSelected(selections);
  };

  const getResearchThemes = async () => {
    const data = await getResearchThemesData();

    setResearchThemes(()=>[ ...data ]);

    let selections = [];

    if (props.research_themes_of_interest) {
      selections = data.filter((h)=> (props.research_themes_of_interest.find((i) => i === h.name)));
    } 

    setResearchThemesOfInterestSelected(selections);
  };

  const runValidations = (data) => {
    const isDataCompleted = data.first_name && data.first_name.length > 0
    && data.last_name && data.last_name.length > 0
    && data.industry && data.industry.length > 0
    && data.country && data.country.length > 0
    && data.country_of_origin && data.country_of_origin.length > 0
    && data.image && data.image.length > 0
    && data.looking_to && data.looking_to.length > 0
    && data.regions_of_interest && data.regions_of_interest.length > 0
    && data.hobbies_of_interest && data.hobbies_of_interest.length > 0
    && data.industries_of_interest && data.industries_of_interest.length > 0;

    setIsButtonDisabled(!isDataCompleted);
  };
  
  const onFieldChanged = (fieldName, fieldValue) => {
    const newValues = { ...data, [fieldName]:fieldValue };
    
    setData(() => (newValues));

    runValidations(newValues);
  };

  const onCountryOfOriginChanged = (selections) => {
    const countrySelected = selections.length === 1 ? selections[0].name : undefined;

    onFieldChanged('country_of_origin', countrySelected);
    setCountryOfOriginSelected(selections);
  };

  const onCountryChanged = (selections) => {
    const countrySelected = selections.length === 1 ? selections[0].name : undefined;

    onFieldChanged('country', countrySelected);
    setCountrySelected(selections);
  };

  const onRegionsOfInterestChanged = (selections) => {
    onFieldChanged('regions_of_interest', selections.map((c)=> c.name));
    setRegionsOfInterestSelected(selections);
  };

  const onIndustriesOfInterestChanged = (selections) => {
    onFieldChanged('industries_of_interest', selections.map((c)=> c.name));
    setIndustriesOfInterestSelected(selections);
  };

  const onHobbiesOfInterestChanged = (selections) => {
    onFieldChanged('hobbies_of_interest', selections.map((c)=> c.name));
    setHobbiesOfInterestSelected(selections);
  };

  const onLookingToOptionsChanged = (selections) => {
    onFieldChanged('looking_to', selections.map((c)=> c.name));
    setLookingToOptionsSelected(selections);
  };

  const onResearchThemesChanged = (selections) => {
    onFieldChanged('research_themes_of_interest', selections.map((c)=> c.name));
    setResearchThemesOfInterestSelected(selections);
  };

  const onSaveClicked = async (event) => {
    event.preventDefault();

    if (loading) {
      return;
    };

    setLoading(true);
    setIsButtonDisabled(true);

    try {
      await updateUserData(data.id, { ...data, 'isCompleteProfile': true  });    
      
      localStorage.setItem("username", `${data.first_name} ${data.last_name}`);
      localStorage.setItem("showCompleteProfileModal", false);
      localStorage.setItem("userimage", data.image ? data.image: undefined);

      window.location.replace(`${process.env.PUBLIC_URL}/user/profile/${data.id}`);
    } catch (error) {
      console.log("error:", error);

      await log(`Edit Profile error: ${error.message ? error.message : JSON.stringify(error)}`);

      setIsButtonDisabled(false);
      setLoading(false);
      setError(`Something went wrong. Please try again.`);
    }
  };

  const onCancelClicked = async (event) => {
    event.preventDefault();

    history(`${process.env.PUBLIC_URL}/login`);
  };

  useEffect(() => {
    runValidations(data);
    getCountries();
    getIndustries();
    getHobbies();
    getRegions();
    getLookingToOptions();
    getResearchThemes();
  }, []);

  return (

    <Fragment>
      <Breadcrumb parent="Donor" title="" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
              <CardBody>
                <div className="cont b-light">
                  <div className="pt-4">
                    <h4>Please complete the following information</h4>
                  </div>
                  <div className="pb-4 text-muted">
                    Required information (<span className="font-danger">*</span>)
                  </div>
                  <Row className="alumni-edit-profile pt-4">
                    <div className="col-sm-6 col-md-6">
                      <FormGroup>
                        <Label className="form-label">Country of origin <span className="font-danger">*</span></Label>
                        <Typeahead
                          id="country_origin_selection"
                          labelKey="name"
                          options={countries}
                          selected={countryOfOriginSelected}
                          placeholder="Select your country of Origin"
                          onChange={onCountryOfOriginChanged}
                        />   
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <FormGroup>
                        <Label className="form-label">Country of residence <span className="font-danger">*</span></Label>
                        <Typeahead
                          id="country_selection"
                          labelKey="name"
                          options={countries}
                          selected={countrySelected}
                          placeholder="Select your country of residence"
                          onChange={onCountryChanged}
                        />   
                      </FormGroup>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <FormGroup>
                        <Label className="form-label">Current industry <span className="font-danger">*</span></Label>
                        <Input
                          type="select"
                          className="form-control"
                          onChange={(event) => onFieldChanged('industry', event.target.value)}
                          value={data.industry || ''}
                        >
                          <option value="">
                            Select an option
                          </option>
                          {industries.map((industry, index) => (
                            <option key={index} value={industry.name}>
                              {industry.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <FormGroup>
                        <Label className="form-label">First Name <span className="font-danger">*</span></Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="First Name"
                          value={data.first_name || ''}
                          onChange={(event) => onFieldChanged('first_name', event.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <FormGroup>
                        <Label className="form-label">Last Name <span className="font-danger">*</span></Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                          value={data.last_name || ''}
                          onChange={(event) => onFieldChanged('last_name', event.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="form-label">Email-Address <span className="font-danger">*</span></Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Email address"
                          value={data.email || ''}
                          disabled
                        />
                      </FormGroup>
                    </div>
                    
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="form-label">Website</Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="https://personal-website.com"
                          value={data.website || ''}
                          onChange={(event) => onFieldChanged('website', event.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="form-label">Linkedin Profile URL</Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="https://linkedin.com/"
                          value={data.linkedin || ''}
                          onChange={(event) => onFieldChanged('linkedin', event.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-12 my-auto pb-4">
                      <Label className="form-label">Profile photo <span className="font-danger">*</span></Label>
                      <div className="col-auto" style={{ maxWidth: '400px' }}>
                        <ImageCropper 
                          width={150}
                          height={150}
                          image={data.image || ''}
                          imageChanged={(image) => onFieldChanged('image', image)}
                        />
                      </div>
                    </div>
                     <div className="col-md-12 bg-primary mt-4" style={ {'height':'2px'} }>
                    </div>
                    <div className="col-md-12 mt-4">
                      <h5>How would you like to interact with others? <i className="fa fa-info-circle" id="PopoverInteractInfo" ></i></h5> 
                      <Popover
                        placement="right"
                        isOpen={popoverInteractInfo}
                        target="PopoverInteractInfo"
                        trigger="hover"
                        toggle={toggleInteractInfo}
                      >
                        <PopoverBody>
                          Please select at least one option.
                        </PopoverBody>
                      </Popover>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <FormGroup>
                        <Label className="form-label">Looking to <span className="font-danger">*</span></Label>
                        <Typeahead
                          id="looking_to"
                          labelKey="name"
                          options={lookingToOptions}
                          selected={lookingToOptionsSelected}
                          multiple
                          placeholder="Select the options"
                          onChange={onLookingToOptionsChanged}
                        />   
                      </FormGroup>
                    </div>
                    <div className="col-md-12 mt-4">
                      <h5>What interests you? <i className="fa fa-info-circle" id="PopoverInterestInfo" ></i></h5> 
                      <Popover
                        placement="right"
                        isOpen={popoverInterestInfo}
                        target="PopoverInterestInfo"
                        trigger="hover"
                        toggle={toggleInterestInfo}
                      >
                        <PopoverBody>
                          Please select at least one option per category.
                        </PopoverBody>
                      </Popover>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <FormGroup>
                        <Label className="form-label">Regions <span className="font-danger">*</span></Label>
                        <Typeahead
                          id="regions_of_interest"
                          labelKey="name"
                          options={regions}
                          selected={regionsOfInterestSelected}
                          multiple
                          placeholder="Select your regions of interest"
                          onChange={onRegionsOfInterestChanged}
                        />   
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <FormGroup>
                        <Label className="form-label">Industries <span className="font-danger">*</span></Label>
                        <Typeahead
                          id="industries_of_interest"
                          labelKey="name"
                          options={industries}
                          selected={industriesOfInterestSelected}
                          multiple
                          placeholder="Select your industries of interest"
                          onChange={onIndustriesOfInterestChanged}
                        />   
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="form-label">Research Themes</Label>
                        <Typeahead
                          id="research_themes_of_interest"
                          labelKey="name"
                          options={researchThemes}
                          selected={researchThemesOfInterestSelected}
                          multiple
                          placeholder="Select your options of interest"
                          onChange={onResearchThemesChanged}
                        />   
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="form-label">Hobbies <span className="font-danger">*</span></Label>
                        <Typeahead
                          id="hobbies_of_interest"
                          labelKey="name"
                          options={hobbies}
                          selected={hobbiesOfInterestSelected}
                          multiple
                          placeholder="Select your hobbies of interest"
                          onChange={onHobbiesOfInterestChanged}
                        />   
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className="pt-4">
                  { error && 
                    <Alert color="danger">
                      <p>{ error }</p>
                    </Alert>
                  }
                  </Row>

                  <Row className="pt-4">
                    <Col className="text-start">
                      <button onClick={onCancelClicked} className="btn btn-light  mt-4" type="submit">
                        Cancel
                      </button>
                    </Col>
                    <Col className="text-end">
                      <button onClick={onSaveClicked} disabled={isButtonDisabled} className="btn btn-primary  mt-4" type="submit">
                        {loading ? 'Saving...' : 'Save' }
                      </button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
          </Col>
        </Row>
      </Container>
    </Fragment>

      
  );
};

export default AlumniProfile;
